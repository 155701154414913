import React, { useEffect, useRef, useState } from 'react';

const Section_Detail = ({order, details, handle_hover, title_color_white}) => {
    const [ detail, setDetail ] = useState({});
    const [ hover, setHover ] = useState(false);

    const ref_container = useRef(null);
    const ref_detail = useRef(null);

    const [ detail_height, setDetail_height ] = useState(0);

    useEffect(() => {
        const result = {...details[order]};
        setDetail(result);
    }, details);

    useEffect(() => {
        if(ref_detail.current) {
            setDetail_height((hover) ? ref_detail.current.scrollHeight : 0);
        }
    }, [hover]);

    function getComponent_Skeleton() {
        return (
            <>
                <p className={'notosan-med px-6 text-sm 2xl:text-base pt-6 invisible ' + (hover ? 'line-clamp-none ' : 'line-clamp-2 ') + (title_color_white ? (hover ? 'text-web-black' : 'text-white') : 'text-web-black')}>{detail.title}</p>

                <div className='w-auto overflow-hidden transition-all duration-300 ease-in-out invisible'>
                    {
                        (detail?.detail?.length > 0) ?
                            detail.detail.map((x, i) => {
                                return (
                                    <p key={i} className='notosan-med text-web-black indent-8 text-sm px-6 py-2 text-xs 2xl:text-sm'>{x}</p>
                                )
                            })
                        :
                            null
                    }
                </div>

                <div className='relative w-full h-10 flex justify-center py-2 items-center invisible'>
                    <div className={'w-6 h-6 bg-white rounded-full transition duration-300 ' + (hover ? 'scale-125 xl:scale-150' : 'scale-100')}></div>

                    {
                        (order === 0) ? <div className='absolute right-0 inset-y-1/2 -translate-y-1/2 w-1/2 h-0.5 bg-white'></div> : null
                    }

                    {
                        ((order !== 0) && (order !== details.length - 1)) ? <div className='absolute right-0 inset-y-1/2 -translate-y-1/2 w-full h-0.5 bg-white'></div> : null
                    }

                    {
                        (order === details.length - 1) ? <div className='absolute left-0 inset-y-1/2 -translate-y-1/2 w-1/2 h-0.5 bg-white'></div> : null
                    }
                </div>

                <div className='w-full h-8 flex justify-center items-center invisible'>
                    <p className={'notosan-med text-white text-center drop-shadow transition duration-300 ' + (hover ? 'text-sm' : 'text-xs')}>พ.ศ. {detail.year}</p>
                </div>
            </>
        )
    }

    return (
        <div ref={ref_container}
        className={'relative w-full h-full flex flex-col justify-end border-white border-r ' + 
            ((order === 0) ? 'rounded-l-md ' : '') + 
            ((order === details.length - 1) ? 'rounded-r-md ' : '') + 
            (hover ? 'backdrop-blur bg-white/30' : 'backdrop-none')}
        onMouseEnter={() => {
            setHover(true);
            handle_hover();
        }}
        onMouseLeave={() => setHover(false)}>
            { 
                //component skeleton is used to make parent container always have height equal to highest child component
                //all components inside same to acutal showed component but have invisible attribute
                getComponent_Skeleton() 
            }

            <div className='absolute bottom-0 left-0 w-full h-fit'>
                <p className={'notosan-med px-6 text-sm 2xl:text-base pt-6 ' + (hover ? 'line-clamp-none ' : 'line-clamp-2 ') + (title_color_white ? (hover ? 'text-web-black' : 'text-white') : 'text-web-black')}>{detail.title}</p>

                <div ref={ref_detail} className='w-auto overflow-hidden transition-all duration-300 ease-in-out' style={{height: detail_height + "px"}}>
                    {
                        (detail?.detail?.length > 0) ?
                            detail.detail.map((x, i) => {
                                return (
                                    <p key={i} className='notosan-med text-web-black indent-8 text-sm px-6 py-2 text-xs 2xl:text-sm'>{x}</p>
                                )
                            })
                        :
                            null
                    }
                </div>

                <div className='relative w-full h-10 flex justify-center py-2 items-center'>
                    <div className={'w-6 h-6 bg-white rounded-full transition duration-300 ' + (hover ? 'scale-125 xl:scale-150' : 'scale-100')}></div>

                    {
                        (order === 0) ? <div className='absolute right-0 inset-y-1/2 -translate-y-1/2 w-1/2 h-0.5 bg-white'></div> : null
                    }

                    {
                        ((order !== 0) && (order !== details.length - 1)) ? <div className='absolute right-0 inset-y-1/2 -translate-y-1/2 w-full h-0.5 bg-white'></div> : null
                    }

                    {
                        (order === details.length - 1) ? <div className='absolute left-0 inset-y-1/2 -translate-y-1/2 w-1/2 h-0.5 bg-white'></div> : null
                    }
                </div>

                <div className='w-full h-8 flex justify-center items-center'>
                    <p className={'notosan-med text-white text-center drop-shadow transition duration-300 ' + (hover ? 'text-sm' : 'text-xs')}>พ.ศ. {detail.year}</p>
                </div>
            </div>
        </div>
    )
}

const Section_Background = ({image, hover}) => {
    return (
        <div className='absolute top-0 left-0 w-full h-full overflow-hidden'>
            <img src={image} className={'w-full h-full object-cover rounded-md transition-all duration-300 ease-in-out ' + (hover ? 'opacity-100 scale-100' : 'opacity-0 scale-110')}/>
        </div>
    )
}

const Five_Timeline = ({events}) => {
    const [ index, setIndex ] = useState(0);
    const [ title_color_white, setTitle_color_white ] = useState(false);

    const [ hovering_img0, setHovering_img0 ] = useState(false);
    const [ hovering_img1, setHovering_img1 ] = useState(false);
    const [ hovering_img2, setHovering_img2 ] = useState(false);
    const [ hovering_img3, setHovering_img3 ] = useState(false);
    const [ hovering_img4, setHovering_img4 ] = useState(false);

    useEffect(() => {
        setHovering_img0(index === 0);
        setHovering_img1(index === 1);
        setHovering_img2(index === 2);
        setHovering_img3(index === 3);
        setHovering_img4(index === 4);
    }, [index]);

    return (
        <div className='relative w-fit rounded-md'>
            <div className='relative w-fit rounded-md grid grid-cols-5 z-10'>
                <Section_Detail order={0} details={events} title_color_white={title_color_white} handle_hover={() => {
                    setIndex(0);
                    setTitle_color_white(false);
                }}/>

                <Section_Detail order={1} details={events} title_color_white={title_color_white} handle_hover={() => {
                    setIndex(1);
                    setTitle_color_white(false);
                }}/>

                <Section_Detail order={2} details={events} title_color_white={title_color_white} handle_hover={() => {
                    setIndex(2);
                    setTitle_color_white(true);
                }}/>

                <Section_Detail order={3} details={events} title_color_white={title_color_white} handle_hover={() => {
                    setIndex(3);
                    setTitle_color_white(true);
                }}/>

                <Section_Detail order={4} details={events} title_color_white={title_color_white} handle_hover={() => {
                    setIndex(4);
                    setTitle_color_white(true);
                }}/>
            </div>

            <Section_Background image={events[0].image} hover={hovering_img0}/>
            <Section_Background image={events[1].image} hover={hovering_img1}/>
            <Section_Background image={events[2].image} hover={hovering_img2}/>
            <Section_Background image={events[3].image} hover={hovering_img3}/>
            <Section_Background image={events[4].image} hover={hovering_img4}/>
        </div>
    )
}

export default Five_Timeline