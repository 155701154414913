import React from 'react';

const Contact = () => {
  return (
    <div className='w-screen h-screen min-w-screen min-h-screen flex justify-center items-center'>
      <p className='notosan-semi text-xl'>This page is currently under development. Please check back later for updates.</p>
    </div>
  )
}

export default Contact