import React, { useEffect, useState } from 'react'

const Timeline = ({events, small_size}) => {
    const [ Events, setEvents ] = useState([]);

    useEffect(() => {
        if(events) {
            setEvents([...events].sort((a, b) => a.year - b.year));
        }
    }, [events]);

    return (
        <div className='w-full h-fit'>
            {
                Events?.map((x, i) => {
                    return (
                        <div key={i} className='w-full h-fit flex items-stretch'>
                            <div className='relative w-10 sm:w-20 min-w-10 sm:min-w-20 flex items-start justify-center'>
                                <div className='w-4 sm:w-6 h-4 sm:h-6 bg-web-gold rounded-full translate-y-full'></div>

                                {
                                    (Events.length === 1) ? 
                                        null
                                    :
                                        <>
                                            { (i === 0) ? <div className='absolute top-0 inset-x-1/2 -translate-x-1/2 w-0.5 h-full bg-web-gold translate-y-6'></div> : null }
                                            { (i === Events.length - 1) ? <div className='absolute top-0 inset-x-1/2 -translate-x-1/2 w-0.5 h-6 bg-web-gold'></div> : null }
                                            { (i !== 0 && i !== Events.length - 1) ? <div className='absolute top-0 inset-x-1/2 -translate-x-1/2 w-0.5 h-full bg-web-gold'></div> : null }
                                        </>
                                }
                            </div>

                            <div className='grow h-fit py-3'>
                                <p className={'text-web-black ' + (small_size ? 'notosan-med text-xs' : 'notosan-semi text-sm')}>พ.ศ. {x.year}</p>
                                <p className={'text-web-black ' + (small_size ? 'notosan-med' : 'notosan-bold text-xl')}>{x.title}</p>
                                
                                {
                                    x.detail.map((y, j) => {
                                        return (
                                            <p key={j} className='notosan-reg text-web-black indent-8 text-sm'>{y}</p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default Timeline