import React from 'react';
import './underlineeffecttext.css';

const UnderlineEffectText = ({text, url}) => {
    return (
        <div className='w-fit'>
            <a href={url} className='relative hover-underline-animation'>
                <h2 className="notosan-med text-web-gold text-start text-3xl">{text}</h2>
            </a>
        </div>
    )
}

export default UnderlineEffectText