import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo/logo.jpg'

import UilClose from '@iconscout/react-unicons/icons/uil-times';
import UilBars from '@iconscout/react-unicons/icons/uil-bars';

const Header = () => {
    const [ show_mobile_nav, setShow_mobile_nav ] = useState(false);
    const navigate = useNavigate();

    const Menu = ({menu, url}) => (
        <Link className='notosan-reg text-web-black cursor-pointer hover:text-web-gold'
        to={url}>
            {menu}
        </Link>
    )

    return (
        <div className='w-full h-fit flex justify-between px-10 py-3 border-b border-grey-300'>
            <div className='w-fit h-fit'>
                <div className='w-full h-fit flex justify-center'>
                    <img src={logo} className='w-28 h-auto cursor-pointer' onClick={() => console.log(navigate("/"))}/>
                </div>

                <div className='relative py-1 px-2'>
                    <h3 className='relative text-web-black text-xs notosan-bold text-center'>Small, but Mighty</h3>
                </div>
            </div>

            <div className='w-fit h-auto justify-end items-center gap-x-10 hidden xl:flex'>
                <Menu menu={'หน้าแรก'} url={'/'}/>
                <Menu menu={'เกี่ยวกับเรา'} url={'/about'}/>
                <Menu menu={'ผลงานของเรา'} url={'/testimonial'}/>
                <Menu menu={'การจัดการความรู้'} url={'/km'}/>
                <Menu menu={'กิจกรรม'} url={'/activities'}/>
                <Menu menu={'ข่าวสารองค์กร'} url={'/news'}/>
                <Menu menu={'ติดต่อเรา'} url={'/contact'}/>
            </div>

            <div className='flex justify-end self-center block xl:hidden' onClick={() => setShow_mobile_nav(true)}>
                <div className='cursor-pointer'>
                    <UilBars size="30" color='#524A4E' />
                </div>
            </div>

            <div className="lg:hidden" role="dialog" aria-modal="true" style={show_mobile_nav ? {display: "block"} : {display: "none"}}>
                <div className="fixed inset-0"></div>

                <div className="fixed inset-y-0 right-0 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 z-50">
                    <div className='flex justify-end my-3'>
                        <div className='cursor-pointer' onClick={() => setShow_mobile_nav(false)}>
                            <UilClose size="30" color='#524A4E' />
                        </div>
                    </div>

                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/">หน้าแรก</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/about">เกี่ยวกับเรา</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/testimonial">ผลงานของเรา</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/km">การจัดการความรู้</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/activities">กิจกรรม</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/news">ข่าวสารองค์กร</Link></div>
                                <div className="notosan-reg text-web-black px-3 py-2"><Link to="/contact">ติดต่อเรา</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header