import React, { useEffect, useState } from 'react';

const ActivityCard = ({title, dateStart, dateEnd, image}) => {
    const [ hover, setHover ] = useState(false);
    const [ date, setDate ] = useState(null);

    useEffect(() => {
        set_date_formatted(dateStart, dateEnd);
    }, []);

    function set_date_formatted(dateStart, dateEnd) {
        const thaiMonths = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"];

        if(dateEnd === null || dateEnd === undefined || dateEnd === '') {
            const objStart = new Date(dateStart);
            const startD = objStart.getDate();
            const startM = objStart.getMonth();
            const startY = objStart.getFullYear();

            setDate(startD + ' ' + thaiMonths[startM] + ' ' + (startY + 543));
        }
        else {
            const objStart = new Date(dateStart);
            const startD = objStart.getDate();
            const startM = objStart.getMonth();
            const startY = objStart.getFullYear();
        
            const objEnd = new Date(dateEnd);
            const endD = objEnd.getDate();
            const endM = objEnd.getMonth();
            const endY = objEnd.getFullYear();

            if(startY !== endY) {
                setDate(startD + ' ' + thaiMonths[startM] + ' ' + (startY + 543) + ' - ' + endD + ' ' + thaiMonths[endM] + ' ' + (endY + 543));
            }
            else if (startM !== endM) {
                setDate(startD + ' ' + thaiMonths[startM] + ' - ' + endD + ' ' + thaiMonths[endM] + ' ' + (endY + 543))
            }
            else {
                setDate(startD + ' - ' + endD + ' ' + thaiMonths[startM] + ' ' + (startY + 543));
            }
        }
    }

    //This Card differs from NewsCard at image height, no detail added, and the way date presented
    //NewCard height is fixed at h-64 while AcitivityCard is auto

    return (
        <div className='w-full h-fit cursor-pointer pb-14' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div className='w-full h-48 rounded-sm overflow-hidden'>
                <img src={image} className={'w-full h-auto object-cover rounded-sm transition-transform duration-300 ' + (hover ? 'scale-125' : 'scale-100')}/>
            </div>
            <p className='notosan-reg text-web-black-secondary text-sm mt-6'>{date}</p>
            <p className='w-full h-fit notosan-med text-web-black text-base line-clamp-2 text-web-gold text-xl'>{title}</p>
        </div>
    )
}

export default ActivityCard