import React, { useState } from 'react';
import './footer.css';
import UilReact from '@iconscout/react-unicons/icons/uil-line';
import ModalConfirmForm from '../../Components/Modal/ModalConfirmForm/ModalConfirmForm';
import Footer_contact_form from './Footer_contact_form';

const Footer = ({form}) => {
    const [ is_SubmitConfirm, setIsSubmitConfirm ] = useState(false);
    const [ is_SubmitLoading, setIsSubmitLoading ] = useState(false);
    const [ is_SubmitSuccess, setIsSubmitSuccess ] = useState(null);
    const [ modal_submit_process, setModal_submit_process ] = useState("โปรดรอสักครู่");

    function handle_submit() {
        if(document.getElementById("contact_name").value === '') {
            document.getElementById("contact_name").focus();
            alert("โปรดระบุชื่อ-นามสกุลผู้ติดต่อ");
            return;
        }

        if(document.getElementById("contact_telephone").value === '') {
            document.getElementById("contact_telephone").focus();
            alert("โปรดระบุเบอร์โทรศัพท์");
            return;
        }

        if(document.getElementById("contact_email").value === '') {
            document.getElementById("contact_email").focus();
            alert("โปรดระบุอีเมลสำหรับติดต่อกลับ");
            return;
        }

        if(document.getElementById("contact_message").value === '') {
            document.getElementById("contact_message").focus();
            alert("โปรดระบุข้อความ");
            return;
        }

        setIsSubmitConfirm(true);
    }

    const handle_cancel = () => {
        setIsSubmitConfirm(false);
        setIsSubmitLoading(false);
        setIsSubmitSuccess(null);
    }

    const handle_confirm = () => {
        const scriptURL = 'https://script.google.com/macros/s/AKfycbyo4ImCSSs_-28icoyXqgd-hyou9YzoqlPTOiAWPYT-hPZZ9G_6MPkbfl9iYXJa1eq7/exec';
        const form = document.forms['contact_form'];

        setIsSubmitLoading(true);
        setModal_submit_process("กำลังส่งข้อมูล");

        fetch(scriptURL, { method: 'POST', body: new FormData(form)})
        .then(res => res.json())
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
          alert("การส่งข้อมูลไม่สำเร็จ");
        })
        .finally(() => {
            setIsSubmitLoading(false);
            setIsSubmitSuccess('success');
        });
    }

    return (
        <>
            <div className='home_contact_container relative pt-20'>
                { (form) ? <Footer_contact_form handle_submit={handle_submit}/> : null }

                <div className='relative mx-10 sm:mx-28 my-10 z-20'>
                    <div className='w-full h-fit notosan-semi text-center text-web-gold text-3xl'>ศูนย์นโยบายและการจัดการสุขภาพ</div>
                    <div className='w-full h-fit notosan-med text-center text-web-gold text-base my-6'>คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี มหาวิทยาลัยมหิดล</div>
                    <div className='w-full h-fit notosan-med text-center text-web-black text-base my-4'>270 ถ.พระรามที่ 6 แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพมหานคร 10400</div>
                    <div className='w-full h-fit notosan-med text-center text-web-black text-base my-4'>เบอร์โทรศัพท์ : 022-003832</div>
                    <div className='w-full h-fit notosan-med text-center text-web-black text-base my-4'>Email : ramahealthpolicy@gmail.com</div>
                </div>

                <div className='relative w-full h-fit flex justify-center gap-5 z-20'>
                    <div className='w-8 h-8 bg-web-black rounded-full flex justify-center items-center cursor-pointer'>
                        <box-icon type='logo' name='facebook' color="white"></box-icon>
                    </div>

                    <div className='w-8 h-8 bg-web-black rounded-full flex justify-center items-center cursor-pointer'>
                        <UilReact size="22" color="#FFFFFF"/>
                    </div>

                    <div className='w-8 h-8 bg-web-black rounded-full flex justify-center items-center cursor-pointer'>
                        <box-icon type='logo' name='youtube' color="white"></box-icon>
                    </div>
                </div>

                <div className='relative notosan-reg w-full h-fit bg-web-black text-white text-center py-4 z-20 mt-20'>
                    Copyright © 2024 Ramathibodi Hospital
                </div>

                { /* Gradient effect to image-background */ }
                <div className='absolute top-0 left-0 w-full h-2/6 bg-gradient-to-b from-white z-10'></div>
                <div className='absolute bottom-0 left-0 w-full h-2/6 bg-gradient-to-t from-white z-10'></div>

                { /* White backdrop to image-background */ }
                <div className='absolute top-0 left-0 w-full h-full bg-white opacity-80 z-0'></div>
            </div>

            <ModalConfirmForm 
                isShow={is_SubmitConfirm} 
                isLoading={is_SubmitLoading} 
                onCancel={handle_cancel} 
                onConfirm={handle_confirm} 
                isSuccess={is_SubmitSuccess} 
                process={modal_submit_process}
                label={"ยืนยันการส่งข้อมูลใช่หรือไม่?"}
                label_success={{
                    title: 'ส่งข้อมูลสำเร็จ',
                    subtitle: 'ขอบคุณที่ส่งข้อความหาเรา โปรดรอการติดต่อกลับจากเรา',
                }}
                label_failed={{
                    title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                    subtitle: null,
                }}/>
        </>
    )
}

export default Footer