import React, { useState } from 'react';

const Admin_add_button = ({label, handle_click}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className={'relative w-full h-full border py-2 px-10 rounded-md cursor-pointer ' + (hover ? 'border-mahidol-blue' : 'border-gray-500')}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_click}>
            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                <span className={'notosan-reg z-10 ' + (hover ? 'text-white' : 'text-web-black')}>{label}</span>
            </div>

            <div className={'absolute top-0 left-0 w-full h-full bg-zinc-700 rounded-md transition-opacity duration-300 ' + (hover ? 'opacity-100' : 'opacity-0')}></div>
        </div>
    )
}

export default Admin_add_button