import React, { useEffect, useRef, useState } from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import cover from '../../../assets/cover/cover_admin.png';
import RoundFullButton from '../../Components/Button/RoundFullButton';
import { useNavigate, useParams } from 'react-router-dom';
import ModalConfirmForm from '../../Components/Modal/ModalConfirmForm/ModalConfirmForm';
import { php_list } from '../../../php/php_list';
import axios from 'axios';
import Admin_add_button from '../../Components/Button/Admin_add_button';
import Modal_add_image from '../../Components/Modal/Modal_add_image';
import Modal_add_text from '../../Components/Modal/Modal_add_text';
import Modal_add_textarea from '../../Components/Modal/Modal_add_textarea';

const Example_image = ({url, handle_delete, handle_change}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-full h-fit cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {
                (handle_delete) ? 
                    <div className='absolute w-fit h-fit top-0 right-0 w-fit h-fit mt-2 mr-2 scale-100 hover:scale-150 transition-transform duration-300 z-30' onClick={handle_delete}>
                        <box-icon name='x' color='#ffffff' ></box-icon>
                    </div>
                :
                    null
            }

            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center' onClick={handle_change}>
                <p className={'notosan-reg text-white transition-opacity duration-300 z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยนรูปข่าว</p>
            </div>

            <div className={'absolute top-0 left-0 w-full h-full bg-black z-10 transition-opacity duration-300 ' + (hover ? 'opacity-70' : 'opacity-0')}></div>
            <img src={url} className='relative w-full h-auto max-h-svh object-cover rounded-sm'/>
        </div>
    )
}

const Example_title = ({title, handle_change}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-full h-fit py-3 cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_change}>
            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                <p className={'notosan-reg text-white transition-opacity duration-300 z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยนหัวข้อข่าว</p>
            </div>
            <div className={'absolute top-0 left-0 w-full h-full bg-black rounded-sm z-10 transition-opacity duration-300 ' + (hover ? 'opacity-70' : 'opacity-0')}></div>
            <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med'>{title}</h3>
        </div>
    )
}

const Modal_add_date = ({isShow, handle_cancel, handle_submit}) => {
    const [ date, setDate ] = useState('');
    const ref = useRef(null);

    useEffect(() => {
        if(ref.current) {
            ref.current.value = '';
        }
    }, [isShow]);

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-2/6 h-fit px-10 py-10 bg-white rounded-md shadow-md'>
                    <p className='notosan-reg text-web-black'>กรุณาระบุวันที่ที่โพสต์ข่าว</p>
                    <input className='w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                    ref={ref} type='date' placeholder='กรุณาระบุวันที่ที่โพสต์ข่าว' onChange={(e) => setDate(e.target.value)}/>

                    <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-4'}>
                        <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_cancel}>ยกเลิก</button>
                        <button type='button' onClick={() => handle_submit(date)}
                        className={'notosan-med w-full h-full py-3 rounded-md ' + (date !== '' ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>ยืนยัน</button>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

const Example_date = ({date, handle_change}) => {
    const [ hover, setHover ] = useState(false);

    const obj_date = new Date(date);
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Bangkok' };
    const formattedDate = obj_date.toLocaleDateString('th-TH', options);

    return (
        <div className='relative w-full h-fit cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_change}>
            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                <p className={'notosan-reg text-white transition-opacity duration-300 z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยนวันที่โพสต์ข่าว</p>
            </div>
            <div className={'absolute top-0 left-0 w-full h-full bg-black rounded-sm z-10 transition-opacity duration-300 ' + (hover ? 'opacity-70' : 'opacity-0')}></div>
            
            <div className='flex gap-5'>
                <p className='notoser-med text-web-gold'>ข่าวสารองค์กร</p>
                <p className='notoser-med text-web-gold'>|</p>
                <p className='notoser-med text-web-gold'>โพสวันที่ : {formattedDate}</p>
            </div>

            <div className='w-32 h-1.5 bg-web-gold my-8'></div>
        </div>
    )
}

const Example_detail = ({detail, handle_change, handle_delete}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-full h-fit cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div className='absolute w-fit h-fit top-0 right-0 w-fit h-fit scale-100 hover:scale-150 transition-transform duration-300 z-30' onClick={handle_delete}>
                <box-icon name='x' color='#ffffff' ></box-icon>
            </div>

            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center' onClick={handle_change}>
                <p className={'notosan-reg text-white transition-opacity duration-300 z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยนเนื้อหาข่าวย่อหน้านี้</p>
            </div>

            <div className={'absolute top-0 left-0 w-full h-full bg-black rounded-sm z-10 transition-opacity duration-300 ' + (hover ? 'opacity-70' : 'opacity-0')}></div>
            <p className='notoser-reg text-web-black indent-16 my-8'>{detail}</p>
        </div>
    )
}

const Admin_news_add = () => {
    const [ image_cover, setImageCover ] = useState(null);
    const [ date, setDate ] = useState(null);
    const [ title, setTitle ] = useState('');
    const [ details, setDetails ] = useState([]);
    const [ relatedImgs, setRelatedImgs ] = useState([]);

    const [ update_details_index, setUpdate_details_index ] = useState(-1);
    const [ update_relatedImg_index, setUpdate_relatedImg_index ] = useState(-1);

    const [ modal_add_img, setModal_add_img ] = useState(false);
    const [ modal_add_text, setModal_add_text ] = useState(false);
    const [ modal_add_date, setModal_add_date ] = useState(false);
    const [ modal_add_textarea, setModal_add_textarea ] = useState(false);
    const [ modal_add_related_img, setModal_add_related_img ] = useState(false);

    const { authorised, id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        console.log(authorised);

        if(authorised !== 'authorised') {
            navigate('/admin');
        }

        if(id !== null && id!== undefined) {
            axios.get(php_list.php_news_read_id, {
                params: {
                    id: id
                }
            })
            .then((response) => {
                // Handle the success response
                console.log(response.data);
    
                if (response.data.success) {
                    setImageCover(response.data.cover);
                    setTitle(response.data.title);
                    setDate(response.data.date);
    
                    setDetails(response.data.details);
                    
                    let imgs = [];
                    response.data.relatedImg.map((x, i) => {
                        imgs.push(x.relatedImg);
                    });

                    setRelatedImgs(imgs);
                } 
                else {
                    alert('Error loading data: ' + response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                alert('Error loading data: ' + error);
            });
        }
    }, []);

    async function handle_add_details_submit(result) {
        await add_new_paragraph();
        await update_orders();

        function add_new_paragraph() {
            return new Promise((resolve, reject) => {
                //update_details_index != -1 in case of updating details
                //otherwise, it store clicked index from state details
                if(update_details_index === -1) {
                    let obj = {};
                    obj.paragraph = details.length;
                    obj.detail = result;

                    setDetails([...details, obj]);
                }
                else {
                    let new_result = [...details];
                    new_result[update_details_index].detail = result;
                    setDetails(new_result);
                }

                resolve();
            });
        }

        function update_orders() {
            return new Promise((resolve, reject) => {
                details.map((x, i) => {
                    details[i].paragraph = i;
                });
        
                setUpdate_details_index(-1);
                setModal_add_textarea(false);
                resolve();
            });
        }
    }

    function handle_add_related_images(result) {
        //update_details_index != -1 in case of updating details
        //otherwise, it store clicked index from state details
        if(update_relatedImg_index === -1) {
            setRelatedImgs([...relatedImgs, result]);
            setModal_add_related_img(false);
        }
        else {
            let new_result = [...relatedImgs];
            new_result[update_relatedImg_index] = result;

            setRelatedImgs(new_result);
            setModal_add_related_img(false);
        }

        setUpdate_relatedImg_index(-1);
    }

    function handle_delete_detail(index) {
        const result = [...details];
        result.splice(index, 1);
        setDetails(result);
    }

    function handle_delete_related_img(index) {
        const result = [...relatedImgs];
        result.splice(index, 1);
        setRelatedImgs(result);
    }

    function recheck_completeness_before_submit() {
        if(image_cover === null || image_cover === undefined || image_cover === '') {
            alert("ยังไม่ได้เพิ่มรูปข่าว โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }

        if(title === null || title === undefined || title === '') {
            alert("ยังไม่ได้เพิ่มหัวข้อข่าว โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }

        if(date === null || date === undefined || date === '') {
            alert("ยังไม่ได้ระบุวันที่โพสต์ข่าว โปรดระบุก่อนกดยืนยัน");
            return;
        }

        if(details.length === 0) {
            alert("ยังไม่ได้เพิ่มเนื้อหาข่าว โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }

        if(relatedImgs.length === 0) {
            alert("ยังไม่ได้เพิ่มรูปภาพข่าวที่เกี่ยวข้อง โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }
        
        setIsSubmitConfirm(true);
    }

    const [ is_SubmitConfirm, setIsSubmitConfirm ] = useState(false);
    const [ is_SubmitLoading, setIsSubmitLoading ] = useState(false);
    const [ is_SubmitSuccess, setIsSubmitSuccess ] = useState(null);
    const [ modal_submit_process, setModal_submit_process ] = useState("โปรดรอสักครู่");

    function submit_data() {
        setIsSubmitLoading(true);

        let obj_date = new Date(date);
        let export_date = obj_date.getFullYear() + "-" + (obj_date.getMonth() + 1) + "-" + obj_date.getDate();

        if(id !== null && id!== undefined) {
            axios.post(php_list.php_news_update, JSON.stringify({
                id: id,
                cover: image_cover,
                title: title,
                date: export_date,
                details: details,
                relatedImgs: relatedImgs,
            }))
            .then(response => {
                // Handle the success response
                console.log(response.data);
    
                if (response.data.success) {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('success');
                } else {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                }
            })
            .catch(errors => {
                // Handle the error
                console.error('Error:', errors);
    
                setIsSubmitLoading(false);
                setIsSubmitSuccess('failed');
            });
        }
        else {
            axios.post(php_list.php_news_write, JSON.stringify({
                cover: image_cover,
                title: title,
                date: export_date,
                details: details,
                relatedImgs: relatedImgs,
            }))
            .then(response => {
                // Handle the success response
                console.log(response.data);
    
                if (response.data.success) {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('success');
                } else {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                }
            })
            .catch(errors => {
                // Handle the error
                console.error('Error:', errors);
    
                setIsSubmitLoading(false);
                setIsSubmitSuccess('failed');
            });
        }
    }

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-80 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                <div className='mx-10 sm:mx-20 xl:mx-48 py-28'>
                    <div>
                        {
                            (image_cover) ? 
                                <Example_image url={image_cover} handle_change={() => setModal_add_img(true)}/>
                            :
                                <div className='w-full h-96'>
                                    <Admin_add_button label={'เพิ่มรูปข่าว'} handle_click={() => setModal_add_img(true)}/>
                                </div>
                        }
                    </div>

                    <div>
                        {
                            (title) ? 
                                <div className='relative w-full h-fit mt-16'>
                                    <Example_title title={title} handle_change={() => setModal_add_text(true)}/>
                                </div>
                            :
                                <div className='w-full h-14 mt-16'>
                                    <Admin_add_button label={'เพิ่มหัวข้อข่าว'} handle_click={() => setModal_add_text(true)}/>
                                </div>
                        }
                    </div>

                    <div>
                        {
                            (date) ? 
                                <div className='relative w-full h-fit my-4'>
                                    <Example_date date={date} handle_change={() => setModal_add_date(true)}/>
                                </div>
                            :
                                <div className='w-full h-14 my-4'>
                                    <Admin_add_button label={'เพิ่มวันที่โพสต์ข่าว'} handle_click={() => setModal_add_date(true)}/>
                                </div>
                        }
                    </div>

                    <div className='w-full h-fit mt-20'>
                        {
                            details.map((x, i) => {
                                return (
                                    <Example_detail key={i} detail={x.detail} handle_delete={() => handle_delete_detail(i)} 
                                    handle_change={() => {
                                        const index = details.findIndex((each) => each.detail === x.detail);
                                        
                                        setUpdate_details_index(index);
                                        setModal_add_textarea(true);
                                    }}/>
                                );
                            })
                        }
                    </div>

                    <div className='w-full h-14 my-4'>
                        <Admin_add_button label={'เพิ่มเนื้อหาข่าว'} handle_click={() => setModal_add_textarea(true)}/>
                    </div>

                    <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-16'>รูปภาพที่เกี่ยวข้อง</h3>
                    <div className='w-32 h-1.5 bg-web-gold my-8'></div>

                    <div className='w-full h-fit grid grid-cols-2 gap-5 mt-12'>
                        {
                            relatedImgs.map((x, i) => {
                                return (
                                    <Example_image key={i} url={x} handle_delete={() => handle_delete_related_img(i)} 
                                    handle_change={() => {
                                        const index = relatedImgs.indexOf(x);

                                        setUpdate_relatedImg_index(index);
                                        setModal_add_related_img(true);
                                    }}/>
                                );
                            })
                        }

                        <div className='w-full min-h-96'>
                            <Admin_add_button label={'เพิ่มรูปภาพที่เกี่ยวข้อง'} handle_click={() => setModal_add_related_img(true)}/>
                        </div>
                    </div>

                    <div className='w-full h-fit flex justify-center gap-5 mt-20'>
                        <RoundFullButton text={'กลับหน้าเดิม'} onclick={() => navigate('/admin/authorised')}/>
                        <RoundFullButton text={(id !== null && id!== undefined) ? 'อัพเดทข่าว' : 'เพิ่มข่าว'} onclick={() => recheck_completeness_before_submit()}/>
                    </div>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
            <Modal_add_image isShow={modal_add_img} handle_cancel={() => setModal_add_img(false)}
            handle_submit={(result) => {
                setImageCover(result);
                setModal_add_img(false);
            }}/>

            <Modal_add_text isShow={modal_add_text} label={'กรุณาระบุหัวข้อของข่าวที่ต้องการเพิ่ม'}
            handle_cancel={() => setModal_add_text(false)} 
            handle_submit={(result) => {
                setTitle(result);
                setModal_add_text(false);
            }}/>

            <Modal_add_date isShow={modal_add_date} handle_cancel={() => setModal_add_date(false)} 
            handle_submit={(result) => {
                setDate(result);
                setModal_add_date(false);
            }}/>

            <Modal_add_textarea isShow={modal_add_textarea} label={'กรุณาระบุเนื้อหาข่าวที่ต้องการเพิ่ม (สำหรับ 1 ย่อหน้า)'}
            handle_cancel={() => setModal_add_textarea(false)} 
            handle_submit={handle_add_details_submit}/>

            <Modal_add_image isShow={modal_add_related_img} handle_cancel={() => setModal_add_related_img(false)} handle_submit={handle_add_related_images}/>

            <ModalConfirmForm 
            isShow={is_SubmitConfirm} 
            isLoading={is_SubmitLoading} 
            isSuccess={is_SubmitSuccess} 
            process={modal_submit_process} 
            onConfirm={submit_data} onCancel={() => {
                setIsSubmitConfirm(false);
                setIsSubmitLoading(false);
                setIsSubmitSuccess(null);
            }}
            label={"ยืนยันการส่งข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'ส่งข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>
        </div>
    )
}

export default Admin_news_add