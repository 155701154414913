const website = 'https://wandertestsite.com/';

export const php_list = {
    php_news_write: website + 'php_news_write.php',
    php_news_read_all: website + 'php_news_read_all.php',
    php_news_read_id: website + 'php_news_read_id.php',
    php_news_update: website + 'php_news_update.php',
    php_news_delete: website + 'php_news_delete.php',

    php_activity_write: website + 'php_activity_write.php',
    php_activity_read_all: website + 'php_activity_read_all.php',
    php_activity_read_id: website + 'php_activity_read_id.php',
    php_activity_update: website + 'php_activity_update.php',
    php_activity_delete: website + 'php_activity_delete.php',

    php_testimonials_write: website + 'php_testimonials_write.php',
    php_testimonials_write_timeline_detail: website + 'php_testimonials_write_timeline_detail.php',
    php_testimonials_read_all: website + 'php_testimonials_read_all.php',
    php_testimonials_read_id_url: website + 'php_testimonials_read_id_url.php',
    php_testimonials_read_id_des: website + 'php_testimonials_read_id_des.php',
    php_testimonials_read_id_timeline: website + 'php_testimonials_read_id_timeline.php',
    php_testimonials_delete: website + 'php_testimonials_delete.php',
    php_testimonials_delete_timeline_detail: website + 'php_testimonials_delete_timeline_detail.php',
    php_testimonials_update: website + 'php_testimonials_update.php',
    php_testimonials_update_favorite: website + 'php_testimonials_update_favorite.php',
}