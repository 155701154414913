import React, { useEffect, useState } from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import cover from '../../assets/cover/cover_news.png';
import title_decorate from '../../assets/decorate/title_decorate.svg';
import NewsCard from '../Components/Card/NewsCard';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { php_list } from '../../php/php_list';
import Pagination from '../Components/Pagination/Pagination';
import { motion, useAnimationControls } from "framer-motion";

const News = () => {
    const [ page, setPage ] = useState(1);
    const [ items_per_page, setItems_per_page ] = useState(8);
    const [ max_page, setMax_page ] = useState(1);
    const [ db, setDB ] = useState([]);
    const [ items, setItems ] = useState([]);

    useEffect(() => {
        let abortcontroller = new AbortController();

        axios.get(php_list.php_news_read_all, { signal: abortcontroller.signal })
        .then((response) => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                setDB([...response.data.results]);
            } 
            else {
                console.log('Error loading data: ' + response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        return () => abortcontroller.abort();
    }, []);

    const [ screen_width, setScreen_width ] = useState(window.innerWidth);

    useEffect(() => {
        setItems_per_page((screen_width >= 1024 && screen_width < 1536) ? 9 : 8);

        const index_start = (page * items_per_page) - items_per_page; //Or (page * 8) - 8
        const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

        setItems(db?.slice(index_start, index_end));
        setMax_page(Math.ceil(db.length / items_per_page));

        //scroll to top
        document.getElementById('page_container').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

        //add animation into page
        animation_controls.start("hidden");

        setTimeout(() => {
            animation_controls.start("visible");
        }, 500);
    }, [page, db]);

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 10,
            transition: { duration: 0.2 }
        },
        visible: (index) => ({
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, delay: index * 0.1 }
        })
    }

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute top-0 left-0 w-full h-14 bg-black'>
                        <div className='relative w-full h-full'>
                            <div className='absolute top-0 left-0 w-full h-full flex justify-start items-center gap-5 z-20'>
                                <Link to={'/'}><p className='notosan-reg text-white ml-10 -translate-y-0.5 cursor-pointer'>หน้าแรก</p></Link>
                                <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                                <p className='notosan-reg text-white -translate-y-0.5'>ข่าวสารองค์กร</p>
                            </div>

                            <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black opacity-80 z-10 py-3 px-4'></div>
                        </div>
                    </div>

                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-30 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                <div id='page_container' className='mx-4 sm:mx-20 2xl:mx-48 py-28'>
                    <div className='w-full h-fit flex flex-col justify-center my-6'>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ข่าวสารเกี่ยวกับศูนย์นโยบายและการจัดการสุขภาพ</h3>
                        <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                    </div>

                    {
                        (items.length === 0) ?
                            <div className='w-full h-fit my-10 flex justify-center'>
                                { /* Loading Spinder */ }
                                <div className={'grid grid-cols-1 gap-10 w-full h-auto min-h-12 mt-8'}>
                                    <div className='flex justify-center items-center w-full h-full bg-pink-bold rounded-md cursor-not-allowed'>
                                        <div role="status">
                                            <svg aria-hidden="true" className="w-6 h-6 text-gray-300 animate-spin dark:text-gray-600 fill-web-gold" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <>
                                <div className='w-full h-fit my-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5'>
                                    {
                                        items.map((x, i) => {
                                            return (
                                                <Link key={i} to={'/news/detail/' + x.id} target="_blank" rel="noopener noreferrer">
                                                    <motion.div
                                                    className='border border-gray-300 px-8 pt-6 rounded-xl cursor-pointer'
                                                    variants={ani_obj}
                                                    initial="hidden"
                                                    animate={animation_controls}
                                                    custom={i}>
                                                        <NewsCard title={x.title} date={x.date} image={x.cover} detail={x.detail}/>
                                                    </motion.div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>

                                <div className='w-full h-fit flex justify-end items-center gap-2'>
                                    <Pagination current_page={page} max_page={max_page} 
                                    handle_change_page={(x) => setPage(x)}/>
                                </div>
                            </>
                    }
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
        </div>
    )
}

export default News