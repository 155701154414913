import React, { useRef } from 'react';

const SearchInput = ({placeholder, handle_search}) => {
    const ref_search_input = useRef(null);

    function clear_input() {
        if(ref_search_input.current) {
            ref_search_input.current.value = '';
            handle_search('');
        }
    }

    return (
        <div className='w-full h-full px-4 py-1.5 border border-gray-300 rounded-full flex justify-between items-center gap-3'>
            <box-icon name='search-alt-2' color='#d1d5db' ></box-icon>
            <input type='text' ref={ref_search_input}
            className='grow h-full min-w-20 outline-0' placeholder={placeholder} onChange={(e) => handle_search(e.target.value)}></input>
            
            <div className='w-fit h-fit flex justify-center items-center cursor-pointer' onClick={() => clear_input()}>
                <box-icon name='x' color='#555555'></box-icon>
            </div>
        </div>
    )
}

export default SearchInput