import React, { useEffect, useState } from 'react';
import title_decorate from '../../assets/decorate/title_decorate.svg';

const TestimonialIntroduction = ({db}) => {
    const founding_year = 2013;
    const [ project_all, setProject_all ] = useState(0);
    const [ project_inProgress, setProject_inProgress ] = useState(0);
    const [ project_finish, setProject_finish ] = useState(0);

    useEffect(() => {
        setProject_all(db.length);

        let in_progress = 0;
        let finish = 0;

        db.forEach((x, i) => {
            if(x.status === 'กำลังดำเนินการ') {
                in_progress++;
            }

            if(x.status === 'เสร็จสิ้นการดำเนินงาน') {
                finish++;
            }

            if(i === db.length - 1) {
                setProject_inProgress(in_progress);
                setProject_finish(finish);
            }
        });
    }, [db]);
    
    return (
        <>
            <p className='notosan-reg text-web-black indent-10 sm:indent-20 text-start mt-6 mb-4'>นับตั้งแต่การก่อตั้งขึ้นในปี พ.ศ. {founding_year + 543} ตลอดช่วงเวลา {new Date().getFullYear() - founding_year} ปีที่ผ่านมา ศูนย์นโยบายและการจัดการสุขภาพดำเนินโครงการและกิจกรรมต่างๆ เพื่อเสริมสร้างความเข้มแข็งให้กับระบบสุขภาพและตอบสนองต่อความต้องการที่เปลี่ยนแปลงไปของสังคมอย่างต่อเนื่อง ปัจจุบัน ศูนย์นโยบายและการจัดการสุขภาพได้ริเริ่มและดำเนินการโครงการไปแล้วทั้งสิ้น {project_all} โครงการ โดยในจำนวนนี้มีโครงการที่ได้เริ่มดำเนินการแล้ว {project_inProgress} โครงการ และดำเนินการแล้วเสร็จสิ้นแล้ว {project_finish} โครงการ</p>
            
            <div className='w-full h-fit grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-10'>
                <div className='w-full h-fit rounded-md drop-shadow bg-white py-6 cursor-pointer 2xl:hover:-translate-y-2 transition duration-300'>
                    <p className='notosan-med text-web-gold text-center'>โครงการ/กิจกรรม</p>
                    <p className='notosan-semi text-web-black text-center text-xl mt-2'>{project_all}</p>
                </div>

                <div className='w-full h-fit rounded-md drop-shadow bg-white py-6 cursor-pointer 2xl:hover:-translate-y-2 transition duration-300'>
                    <p className='notosan-med text-web-gold text-center'>กำลังดำเนินการ</p>
                    <p className='notosan-semi text-web-black text-center text-xl mt-2'>{project_inProgress}</p>
                </div>

                <div className='w-full h-fit rounded-md drop-shadow bg-white py-6 cursor-pointer 2xl:hover:-translate-y-2 transition duration-300 col-span-1 sm:col-span-2 lg:col-span-1'>
                    <p className='notosan-med text-web-gold text-center'>ดำเนินการแล้วเสร็จ</p>
                    <p className='notosan-semi text-web-black text-center text-xl mt-2'>{project_finish}</p>
                </div>
            </div>
        </>
    )
}

export default TestimonialIntroduction