import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Timeline from '../../Timeline/Timeline';
import axios from 'axios';
import { php_list } from '../../../../php/php_list';
import Rama_front from '../../../../assets/testimonial/rama_front.jpg';
import { motion, useAnimationControls } from 'framer-motion';

const ModalTestimonial_detail = ({isShow, detail, handle_close}) => {
    const [ cover, setCover ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ founder, setFounder ] = useState([]);
    const [ url, setURL ] = useState([]);
    const [ status, setStatus ] = useState('');
    const [ description, setDescription ] = useState([]);
    const [ timeline, setTimeline ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const [ img_height, setImg_height ] = useState(0);
    const [ img_width, setImg_width ] = useState(10);
    const ref_img = useRef(null);

    useEffect(() => {
        if(detail) {
            setCover(detail.image);
            setTitle(detail.title);
            setFounder(Array.isArray(detail.founder) ? [...detail.founder] : []);
            setURL(Array.isArray(detail.url) ? [...detail.url] : []);
            setStatus(detail.status);
            setDescription(Array.isArray(detail.description) ? [...detail.description] : []);
            setTimeline(Array.isArray(detail.timeline) ? [...detail.timeline] : []);
        }

        document.getElementById('btn_close').scrollIntoView();
    }, [detail]);

    useEffect(() => {
        //scroll to top before close modal
        if(!isShow) {
            document.getElementById("btn_close").scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }

        //set animation
        if(ref_img.current) {
            setImg_height(isShow ? ref_img.current.scrollHeight : 0);
            setImg_width(isShow ? ref_img.current.scrollWidth : 10);

            setTimeout(() => {
                
            }, 500);
        }

        //load data
        let abortcontroller = new AbortController();

        if(isShow) {
            setLoading(true);

            const fetch_data = async () => {
                await axios.get(php_list.php_testimonials_read_id_url, {
                    signal: abortcontroller.signal,
                    params: {
                        id: detail.id
                    }
                })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        setURL([...response.data.result]);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error loading data: ' + error);
                });

                await axios.get(php_list.php_testimonials_read_id_des, {
                    signal: abortcontroller.signal,
                    params: {
                        id: detail.id
                    }
                })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        let result = [...response.data.result];
                        result.sort((a, b) => a.paragraph - b.paragraph);
                        
                        let result_description = [];
                        for(let i = 0; i < result.length; i++) {
                            result_description.push(result[i].description);
                        }

                        setDescription(result_description);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error loading data: ' + error);
                });

                await axios.get(php_list.php_testimonials_read_id_timeline, {
                    signal: abortcontroller.signal,
                    params: {
                        id: detail.id
                    }
                })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        let result = [...response.data.result];
                        result.sort((a, b) => a.year - b.year);
                        
                        let result_timeline = [];
                        for(let i = 0; i < result.length; i++) {
                            let obj = {
                                year: result[i].year,
                                title: result[i].title,
                                detail: result[i].detail.split("{{}}"),
                            }

                            result_timeline.push(obj);
                        }

                        setTimeline(result_timeline);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error loading data: ' + error);
                });
            }

            fetch_data();
        }
        else {
            setLoading(false);
        }

        return () => abortcontroller.abort();
    }, [isShow]);

    function green_status(status) {
        return (
            <p className='notosan-med text-green-700'>{status}</p>
        )
    }

    function red_status(status) {
        return (
            <p className='notosan-med text-red-700'>{status}</p>
        )
    }

    function yellow_status(status) {
        return (
            <p className='notosan-med text-yellow-700'>{status}</p>
        )
    }

    return (
        <>
            { /* Modal confirm submit */ }
            <div className={'absolute top-0 left-0 w-full h-full z-20 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
                { /* testimonial modal in classlist is used to remove scrollbar as code imported from css file */ }
                <div className='testimonial modal w-11/12 h-full overflow-y-scroll'>
                    <div className='relative w-full h-fit bg-white drop-shadow-md rounded-md px-8 sm:px-20 py-20 sm:pt-32 pb-28 my-10 sm:my-20'>
                        <div className='absolute top-5 right-5 cursor-pointer z-50' id='btn_close' onClick={handle_close}>
                            <box-icon name='x' color='#555555'></box-icon>
                        </div>

                        <div className='w-full h-fit block lg:flex gap-10'>
                            <div className={'w-60 h-60 lg:h-96 min-w-60 object-cover rounded-md overflow-hidden transition-all duration-500 z-50'}
                            style={{height: img_height + 'px', width: img_width + 'px'}}>
                                <img ref={ref_img} src={cover} className='w-60 h-60 lg:h-96 object-cover rounded-md'/>
                            </div>

                            <div className='grow mt-12 lg:mt-0 z-50'>
                                <div className='relative w-full h-40 hidden'>
                                    <img src={Rama_front} className='w-full h-40 object-cover rounded-l-md'/>

                                    <div className='absolute top-0 left-0 w-full h-40 bg-web-gold mix-blend-multiply rounded-l-md opacify-70'></div>
                                    <div className='absolute top-0 left-0 w-full h-40 bg-web-gold mix-blend-multiply rounded-l-md'></div>

                                    <div className='absolute bottom-0 left-0 px-8 py-4'>
                                        <p className='notosan-bold text-2xl text-white'>{title}</p>
                                    </div>
                                </div>

                                <div className='w-full h-fit'>
                                    <p className='notosan-bold text-xl text-web-black'>โครงการ/กิจกรรม</p>
                                    <p className='notosan-reg text-web-black'>{title}</p>
                                </div>

                                <div className='w-full h-fit mt-6'>
                                    <p className='notosan-bold text-xl text-web-black'>ผู้รับผิดชอบโครงการ/กิจกรรม</p>

                                    {
                                        founder.map((x, i) => {
                                            return (
                                                <p key={i} className='notosan-reg text-web-black'>{i + 1}. {x}</p>
                                            )
                                        })
                                    }
                                </div>

                                <div className='w-full h-fit mt-6'>
                                    <p className='notosan-bold text-xl text-web-black'>เว็บไซต์ที่เกี่ยวข้อง</p>
                                    
                                    {
                                        (url.length === 0) ?
                                            (!loading) ? 
                                                <div role="status" className="animate-pulse my-4">
                                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                                    <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                                </div>
                                            :
                                                <p className='notosan-reg text-web-black'>ไม่มีลิงก์ที่เกี่ยวข้อง</p>
                                        :
                                            url.map((x, i) => {
                                                return (
                                                    <Link key={i} to={x.url} target="_blank" rel="noopener noreferrer">
                                                        <p className='notosan-reg text-web-black cursor-default'>{i + 1}. <span className='notosan-reg text-web-black cursor-pointer hover:text-web-gold hover:underline'>{x.title}</span></p>
                                                    </Link>
                                                );
                                            })
                                    }
                                </div>

                                <div className='w-full h-fit mt-6'>
                                    <p className='notosan-bold text-xl text-web-black'>สถานะการดำเนินโครงการ/กิจกรรม</p>

                                    { (status === 'กำลังดำเนินการ') ? green_status(status) : null }
                                    { (status === 'เสร็จสิ้นการดำเนินงาน') ? green_status(status) : null }
                                    { (status === 'เสร็จสิ้นการดำเนินงาน และอยู่ระหว่างการขยายผล') ? green_status(status) : null }
                                    { (status === 'ยกเลิกโครงการ') ? red_status(status) : null }
                                    { (status === 'ระงับชั่วคราว') ? yellow_status(status) : null }
                                </div>

                                <div className='w-full h-fit mt-6'>
                                    <p className='notosan-bold text-xl text-web-black'>รายละเอียดของโครงการ/กิจกรรม</p>

                                    {
                                        (description.length === 0) ?
                                            (!loading) ? 
                                                <div role="status" className="max-w-sm animate-pulse my-6">
                                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                                                </div>
                                            :
                                                <p className='notosan-reg text-web-black'>ยังไม่มีรายละเอียดของโครงการ/กิจกรรม</p>
                                        :
                                            null
                                    }
                                    
                                    {
                                        description.map((x, i) => {
                                            return (
                                                <p key={i} className='notosan-reg text-web-black indent-10 sm:indent-20'>{x}</p>
                                            )
                                        })
                                    }
                                </div>

                                <div className='w-full h-fit mt-8'>
                                    <p className='notosan-bold text-xl text-web-black mb-2'>รายละเอียดการดำเนินการ</p>

                                    {
                                        (timeline.length === 0) ?
                                            (!loading) ? 
                                                <div role="status" className="max-w-sm animate-pulse my-6">
                                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                                                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                                                </div>
                                            :
                                                <p className='notosan-reg text-web-black'>ยังไม่มีรายละเอียดการดำเนินการ</p>
                                        :
                                            null
                                    }

                                    <Timeline events={timeline} small_size={false}/>
                                </div>
                            </div>
                        </div>

                        <button className='notosan-med w-full h-full bg-mahidol-blue hover:bg-mahidol-yellow py-3 text-white rounded-md mt-16' onClick={handle_close}>ปิดหน้านี้</button>
                    </div>
                </div>
            </div>

            { /* Modal backdrop */ }
            <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-10 ' + (isShow ? 'block' : 'hidden')}></div>
        </>
    )
}

export default ModalTestimonial_detail