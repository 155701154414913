import React, { useEffect, useRef, useState } from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import cover from '../../../assets/cover/cover_admin.png';
import title_decorate from '../../../assets/decorate/title_decorate.svg';
import RoundFullButton from '../../Components/Button/RoundFullButton';
import { useNavigate, useParams } from 'react-router-dom';
import ModalConfirmForm from '../../Components/Modal/ModalConfirmForm/ModalConfirmForm';
import axios from 'axios';
import { php_list } from '../../../php/php_list';
import Admin_add_button from '../../Components/Button/Admin_add_button';
import Modal_add_text from '../../Components/Modal/Modal_add_text';
import Modal_add_image from '../../Components/Modal/Modal_add_image';

const Example_title = ({title, handle_change}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-full h-fit py-3 cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_change}>
            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                <p className={'notosan-reg text-white transition-opacity duration-300 z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยนหัวข้อกิจกรรม</p>
            </div>
            <div className={'absolute top-0 left-0 w-full h-full bg-black rounded-sm z-10 transition-opacity duration-300 ' + (hover ? 'opacity-70' : 'opacity-0')}></div>
            <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med'>{title}</h3>
        </div>
    )
}

const Example_image = ({url, handle_delete, handle_change}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-full h-fit cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {
                (handle_delete) ? 
                    <div className='absolute w-fit h-fit top-0 right-0 w-fit h-fit mt-2 mr-2 scale-100 hover:scale-150 transition-transform duration-300 z-30' onClick={handle_delete}>
                        <box-icon name='x' color='#ffffff' ></box-icon>
                    </div>
                :
                    null
            }

            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center' onClick={handle_change}>
                <p className={'notosan-reg text-white transition-opacity duration-300 z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยนรูปข่าว</p>
            </div>

            <div className={'absolute top-0 left-0 w-full h-full bg-black z-10 transition-opacity duration-300 ' + (hover ? 'opacity-70' : 'opacity-0')}></div>
            <img src={url} className='relative w-full h-auto max-h-svh object-cover rounded-sm'/>
        </div>
    )
}

const Modal_add_date = ({isShow, handle_cancel, handle_submit}) => {
    const [ dateStart, setDateStart ] = useState('');
    const [ dateEnd, setDateEnd ] = useState('');
    const ref = useRef(null);

    useEffect(() => {
        if(ref.current) {
            ref.current.value = '';
        }
    }, [isShow]);

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-2/6 h-fit px-10 py-10 bg-white rounded-md shadow-md'>
                    <p className='notosan-reg text-web-black'>กรุณาระบุวันที่เริ่มกิจกรรม</p>
                    <input className='w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                    ref={ref} type='date' placeholder='กรุณาระบุวันที่ที่โพสต์ข่าว' onChange={(e) => setDateStart(e.target.value)}/>

                    <p className='notosan-reg text-web-black'>กรุณาระบุวันที่สิ้นสุดกิจกรรม (ถ้ามี)</p>
                    <input className='w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                    ref={ref} type='date' placeholder='กรุณาระบุวันที่ที่โพสต์ข่าว' onChange={(e) => setDateEnd(e.target.value)}/>

                    <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-4'}>
                        <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_cancel}>ยกเลิก</button>
                        <button type='button' onClick={() => handle_submit(dateStart, dateEnd)}
                        className={'notosan-med w-full h-full py-3 rounded-md ' + ((dateStart !== '' || dateEnd !== '') ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>ยืนยัน</button>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

const Example_date = ({date, handle_change}) => {
    const [ hover, setHover ] = useState(false);

    const obj_date = new Date(date);
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Bangkok' };
    const formattedDate = obj_date.toLocaleDateString('th-TH', options);

    return (
        <div className='relative w-full h-fit cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_change}>
            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                <p className={'notosan-reg text-white transition-opacity duration-300 z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยนวันที่โพสต์ข่าว</p>
            </div>
            <div className={'absolute top-0 left-0 w-full h-full bg-black rounded-sm z-10 transition-opacity duration-300 ' + (hover ? 'opacity-70' : 'opacity-0')}></div>
            
            <p className='notosan-med text-web-black my-4'>ระยะเวลาของกิจกรรม : {date}</p>
            <div className='w-32 h-1.5 bg-web-gold my-8'></div>
        </div>
    )
}

const Admin_activities_add = () => {
    const [ image_cover, setImageCover ] = useState(null);
    const [ dateStart, setDateStart ] = useState(null);
    const [ dateEnd, setDateEnd ] = useState(null);
    const [ date, setDate ] = useState(null);
    const [ title, setTitle ] = useState('');

    const [ modal_add_img, setModal_add_img ] = useState(false);
    const [ modal_add_text, setModal_add_text ] = useState(false);
    const [ modal_add_date, setModal_add_date ] = useState(false);

    const { authorised, id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        if(authorised !== 'authorised') {
            navigate('/admin');
        }

        if(id !== null && id!== undefined) {
            axios.get(php_list.php_activity_read_id, {
                params: {
                    id: id
                }
            })
            .then((response) => {
                // Handle the success response
                console.log(response.data);
    
                if (response.data.success) {
                    setImageCover(response.data.cover);
                    setTitle(response.data.title);
                    setDateStart(response.data.dateStart);
                    setDateEnd(response.data.dateEnd);
                    set_date_formatted(dateStart, dateEnd);
                } 
                else {
                    alert('Error loading data: ' + response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                alert('Error loading data: ' + error);
            });
        }
    }, []);

    function set_date_formatted(dateStart, dateEnd) {
        const thaiMonths = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"];

        if(dateEnd === null || dateEnd === undefined || dateEnd === '') {
            const objStart = new Date(dateStart);
            const startD = objStart.getDate();
            const startM = objStart.getMonth();
            const startY = objStart.getFullYear();

            setDate(startD + ' ' + thaiMonths[startM] + ' ' + (startY + 543));
        }
        else {
            const objStart = new Date(dateStart);
            const startD = objStart.getDate();
            const startM = objStart.getMonth();
            const startY = objStart.getFullYear();
        
            const objEnd = new Date(dateEnd);
            const endD = objEnd.getDate();
            const endM = objEnd.getMonth();
            const endY = objEnd.getFullYear();

            if(startY !== endY) {
                setDate(startD + ' ' + thaiMonths[startM] + ' ' + (startY + 543) + ' - ' + endD + ' ' + thaiMonths[endM] + ' ' + (endY + 543));
            }
            else if (startM !== endM) {
                setDate(startD + ' ' + thaiMonths[startM] + ' - ' + endD + ' ' + thaiMonths[endM] + ' ' + (endY + 543))
            }
            else {
                setDate(startD + ' - ' + endD + ' ' + thaiMonths[startM] + ' ' + (startY + 543));
            }
        }
    }

    function recheck_completeness_before_submit() {
        if(title === null || title === undefined || title === '') {
            alert("ยังไม่ได้เพิ่มหัวข้อกิจกรรม โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }

        if(date === null || date === undefined || date === '') {
            alert("ยังไม่ได้ระบุระยะเวลากิจกรรม โปรดระบุก่อนกดยืนยัน");
            return;
        }

        if(image_cover === null || image_cover === undefined || image_cover === '') {
            alert("ยังไม่ได้เพิ่มรูปกิจกรรม โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }

        setIsSubmitConfirm(true);
    }

    const [ is_SubmitConfirm, setIsSubmitConfirm ] = useState(false);
    const [ is_SubmitLoading, setIsSubmitLoading ] = useState(false);
    const [ is_SubmitSuccess, setIsSubmitSuccess ] = useState(null);
    const [ modal_submit_process, setModal_submit_process ] = useState("โปรดรอสักครู่");

    function submit_data() {
        setIsSubmitLoading(true);

        let obj_dateStart = new Date(dateStart);
        let export_dateStart = obj_dateStart.getFullYear() + "-" + (obj_dateStart.getMonth() + 1) + "-" + obj_dateStart.getDate();

        let obj_dateEnd = new Date(dateEnd);
        let export_dateEnd = obj_dateEnd.getFullYear() + "-" + (obj_dateEnd.getMonth() + 1) + "-" + obj_dateEnd.getDate();

        if(id !== null && id!== undefined) {
            axios.post(php_list.php_activity_update, JSON.stringify({
                id: id,
                cover: image_cover,
                title: title,
                dateStart: dateStart,
                dateEnd: dateEnd,
            }))
            .then(response => {
                // Handle the success response
                console.log(response.data);
    
                if (response.data.success) {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('success');
                } else {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                }
            })
            .catch(errors => {
                // Handle the error
                console.error('Error:', errors);
    
                setIsSubmitLoading(false);
                setIsSubmitSuccess('failed');
            });
        }
        else {
            axios.post(php_list.php_activity_write, JSON.stringify({
                cover: image_cover,
                title: title,
                dateStart: export_dateStart,
                dateEnd: export_dateEnd,
            }))
            .then(response => {
                // Handle the success response
                console.log(response.data);
    
                if (response.data.success) {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('success');
                } else {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                }
            })
            .catch(errors => {
                // Handle the error
                console.error('Error:', errors);
    
                setIsSubmitLoading(false);
                setIsSubmitSuccess('failed');
            });
        }
    }

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-80 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                <div className='mx-10 sm:mx-20 xl:mx-48 pt-20 pb-28'>
                    <div className='w-full h-fit flex flex-col justify-center my-6'>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>กิจกรรมของศูนย์นโยบายและการจัดการสุขภาพ</h3>
                        <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                    </div>

                    <div>
                        {
                            (title) ?
                                <div className='relative w-full h-fit mt-16'>
                                    <Example_title title={title} handle_change={() => setModal_add_text(true)}/>
                                </div>
                            :
                                <div className='w-full h-14 mt-16'>
                                    <Admin_add_button label={'เพิ่มหัวข้อกิจกรรม'} handle_click={() => setModal_add_text(true)}/>
                                </div>
                        }
                    </div>

                    <div>
                        {
                            (date) ? 
                                <div className='relative w-full h-fit my-4'>
                                    <Example_date date={date} handle_change={() => setModal_add_date(true)}/>
                                </div>
                            :
                                <div className='w-full h-14 my-4'>
                                    <Admin_add_button label={'เพิ่มระยะเวลากิจกรรม'} handle_click={() => setModal_add_date(true)}/>
                                </div>
                        }
                    </div>

                    <div>
                        {
                            (image_cover) ? 
                                <Example_image url={image_cover} handle_change={() => setModal_add_img(true)}/>
                            :
                                <div className='w-full h-96'>
                                    <Admin_add_button label={'เพิ่มรูปรายละเอียดกิจกรรม'} handle_click={() => setModal_add_img(true)}/>
                                </div>
                        }
                    </div>

                    <div className='w-full h-fit flex justify-center gap-5 mt-20'>
                        <RoundFullButton text={'กลับหน้าเดิม'} onclick={() => navigate('/admin/authorised')}/>
                        <RoundFullButton text={(id !== null && id!== undefined) ? 'อัพเดทข่าว' : 'เพิ่มกิจกรรม'} onclick={() => recheck_completeness_before_submit()}/>
                    </div>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
            <Modal_add_text isShow={modal_add_text} label={'กรุณาระบุหัวข้อของกิจกรรมที่ต้องการเพิ่ม'}
            handle_cancel={() => setModal_add_text(false)} 
            handle_submit={(result) => {
                setTitle(result);
                setModal_add_text(false);
            }}/>

            <Modal_add_image isShow={modal_add_img} handle_cancel={() => setModal_add_img(false)} 
            handle_submit={(result) => {
                setImageCover(result);
                setModal_add_img(false);
            }}/>

            <Modal_add_date isShow={modal_add_date} handle_cancel={() => setModal_add_date(false)} 
            handle_submit={(dateStart, dateEnd) => {
                //dateStart cannot be empty, set dateEnd to null only when it is empty ('')
                setDateStart(dateStart);
                setDateEnd(dateEnd === '' ? null : dateEnd);
                set_date_formatted(dateStart, dateEnd);
                setModal_add_date(false);
            }}/>

            <ModalConfirmForm isShow={is_SubmitConfirm} isLoading={is_SubmitLoading} isSuccess={is_SubmitSuccess} process={modal_submit_process} 
            onConfirm={submit_data} onCancel={() => {
                setIsSubmitConfirm(false);
                setIsSubmitLoading(false);
                setIsSubmitSuccess(null);
            }}
            label={"ยืนยันการส่งข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'ส่งข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>
        </div>
    )
}

export default Admin_activities_add