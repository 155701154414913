import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Admin_add_button from '../../Button/Admin_add_button';
import { status_list } from '../../../Testimonial/status_list';
import { delay, motion, useAnimationControls } from 'framer-motion';
import Modal_add_image from '../Modal_add_image';
import Modal_add_text from '../Modal_add_text';
import Modal_add_textarea from '../Modal_add_textarea';
import { php_list } from '../../../../php/php_list';
import axios from 'axios';

const Modal_Waiting = ({isShow}) => {
    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-fit h-fit bg-white px-14 py-14 rounded-md'>
                    { /* Loading Spinder */ }
                    <div className='flex justify-center items-center w-full h-full bg-pink-bold rounded-md cursor-not-allowed'>
                        <div role="status">
                            <svg aria-hidden="true" className="w-6 h-6 text-gray-300 animate-spin dark:text-gray-600 fill-web-gold" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

const Modal_add_url = ({isShow, handle_cancel, handle_submit}) => {
    const [ title, setTitle ] = useState('');
    const [ url, setURL ] = useState('');
    const ref_title = useRef(null);
    const ref_url = useRef(null);

    useEffect(() => {
        if(ref_title.current) {
            ref_title.current.value = '';
        }

        if(ref_url.current) {
            ref_url.current.value = '';
        }
    }, [isShow]);

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-2/6 h-fit px-10 py-10 bg-white rounded-md shadow-md'>
                    <p className='notosan-reg text-web-black'>กรุณาระบุข้อความที่จะแสดง (ข้อความที่แสดงไม่ใช่ลิงก์ที่เกี่ยวข้อง)</p>
                    <input className='w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                    ref={ref_title} type='text' placeholder={"กรุณาระบุข้อความที่จะแสดง"} onChange={(e) => setTitle(e.target.value)}/>

                    <p className='notosan-reg text-web-black'>กรุณาระบุลิงก์ที่เกี่ยวข้อง</p>
                    <input className='w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                    ref={ref_url} type='text' placeholder={"กรุณาระบุลิงก์ที่เกี่ยวข้อง"} onChange={(e) => setURL(e.target.value)}/>

                    <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-4'}>
                        <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_cancel}>ยกเลิก</button>
                        <button type='button' onClick={() => handle_submit(title, url)}
                        className={'notosan-med w-full h-full py-3 rounded-md ' + ((title.length > 0 && url.length > 0) ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>ยืนยัน</button>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

const Modal_add_status = ({isShow, handle_cancel, handle_submit}) => {
    const [ status, setStatus ] = useState('');
    const [ active_btn, setActive_btn ] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        /*
        if(ref.current) {
            ref.current.value = '';
        }
        */
    }, [isShow]);

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-2/6 h-fit px-10 py-10 bg-white rounded-md shadow-md'>
                    <p className='notosan-reg text-web-black'>กรุณาระบุสถานะการดำเนินการของโครงการ/กิจกรรม</p>
                    <select ref={ref} onChange={(e) => {
                        setStatus(e.target.value);
                        setActive_btn(ref.current.selectedIndex !== 0);
                    }}
                    className='w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 my-4 cursor-pointer focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'>
                        <option value={'default'}>โปรดเลือกสถานะการดำเนินงาน...</option>
                        
                        {
                            status_list.map((x, i) => {
                                return (
                                    <option key={i} value={x}>{x}</option>
                                )
                            })
                        }
                    </select>

                    <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-4'}>
                        <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_cancel}>ยกเลิก</button>
                        <button type='button' onClick={() => handle_submit(status)}
                        className={'notosan-med w-full h-full py-3 rounded-md ' + (active_btn ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>ยืนยัน</button>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

const Modal_add_timeline = ({isShow, handle_cancel, handle_submit}) => {
    const [ year, setYear ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ details, setDetails ] = useState([]);
    const [ opt_years, setOpt_years ] = useState([]);
    const [ edit, setEdit ] = useState(false);
    const [ edited_index, setEdited_index ] = useState(-1);

    const ref_year = useRef(null);
    const ref_title = useRef(null);
    const ref_detail = useRef(null);
    const ref_btn1 = useRef(null);
    const ref_btn2 = useRef(null);

    useEffect(() => {
        let options = [];

        for(let i = 2500; i <= (new Date().getFullYear() + 543); i++) {
            options.push(i);

            if(i === new Date().getFullYear() + 543) {
                options = options.sort((a, b) => b - a);
                setOpt_years(options);
            }
        }
    }, []);

    useEffect(() => {
        if(ref_year.current) {
            ref_year.current.selectedIndex = 0;
        }

        if(ref_title.current) {
            ref_title.current.value = '';
        }

        if(ref_detail.current) {
            ref_detail.current.value = '';
        }
    }, [isShow]);

    const btn1_controls = useAnimationControls();
    const btn2_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: { duration: 1, }
        }
    }

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-5/6 h-fit max-h-full px-10 py-10 bg-white rounded-md shadow-md grid grid-cols-2 gap-10'>
                    <div className='w-full h-fit'>
                        <p className='notosan-bold text-xl text-web-black mb-4'>รายละเอียดของโครงการ/กิจกรรม</p>
                        <p className='notosan-reg text-web-black'>กรุณาระบุปี พ.ศ. ของเหตุการณ์</p>
                        <select ref={ref_year} onChange={(e) => setYear((e.target.value === 'default') ? '' : e.target.value)}
                        className='w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 my-4 cursor-pointer focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'>
                            <option value={'default'}>กรุณาระบุปี พ.ศ. ของเหตุการณ์...</option>

                            {
                                opt_years.map((x, i) => {
                                    return (
                                        <option key={i} value={x}>พ.ศ. {x}</option>
                                    )
                                })
                            }
                        </select>

                        <p className='notosan-reg text-web-black'>โปรดระบุหัวข้อของเหตุการณ์</p>
                        <input ref={ref_title} onChange={(e) => setTitle(e.target.value)}
                        className='w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                        type='text' placeholder={'โปรดระบุหัวข้อของเหตุการณ์'}/>

                        <p className='notosan-reg text-web-black'>กรุณาระบุรายละเอียดของเหตุการณ์</p>
                        <div className='w-full h-fit max-h-80 overflow-y-scroll'>
                            {
                                (details.length === 0) ?
                                    <p className='notosan-reg text-web-black-secondary text-center py-6'>ยังไม่ได้เพิ่มรายละเอียดของเหตุการณ์</p>
                                :
                                    details.map((x, i) => {
                                        return (
                                            <Edible_Timeline key={i} order={i} event={x.detail} 
                                            active={x.active}
                                            edited={x.edited}
                                            handle_edit={() => {
                                                if(x.active) {
                                                    let new_result = [...details];

                                                    for(let j = 0; j < new_result.length; j++) {
                                                        new_result[j].active = (j === i);
                                                        new_result[j].edited = (j === i);

                                                        if(j === new_result.length - 1) {
                                                            setEdited_index(i);
                                                            setDetails(new_result);
                                                            ref_detail.current.value = x.detail;
                                                            btn1_controls.start("hidden");

                                                            setTimeout(() => {
                                                                //animation controls use 500ms to make button disappear
                                                                //so, delay state change to 500ms before it changes to hidden
                                                                setEdit(true);
                                                                btn2_controls.start("visible");
                                                            }, 500)
                                                        }
                                                    }
                                                }
                                            }}
                                            handle_delete={() => {
                                                if(x.active) {
                                                    let new_result = [...details];
                                                    new_result.splice(i, 1);
                                                    setDetails(new_result);
                                                }
                                            }}/>
                                        );
                                    })
                            }
                        </div>

                        <textarea ref={ref_detail} className='w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                        type='text' rows={5} placeholder='กรุณาระบุรายละเอียดของเหตุการณ์ (สำหรับ 1 ย่อหน้า)'/>

                        <motion.div 
                        variants={ani_obj}
                        initial="visible"
                        animate={btn1_controls}
                        ref={ref_btn1} className={'w-full h-12 ' + (edit ? 'hidden' : 'block')}>
                            <Admin_add_button label={"เพิ่มรายละเอียดของโครงการ/กิจกรรม"} handle_click={() => {
                                if(ref_detail.current) {
                                    if(ref_detail.current.value !== '') {
                                        /*
                                            Note: 
                                            This is structure of details
                                            details = [
                                                {  
                                                    detail: '...',
                                                    active: true,
                                                    edited: false,
                                                }
                                            ]

                                            Note: 
                                            active is to disable other paragraph when edit, and focus only to edited paragraph
                                            edited is to separate normal state and edit state (in normal state, active is true too)
                                        */

                                        const obj = { 
                                            detail: ref_detail.current.value, 
                                            active: true, 
                                            edited: false 
                                        };

                                        setDetails([...details, obj]);
                                        ref_detail.current.value = '';
                                    }
                                }
                            }}/>
                        </motion.div>

                        <motion.div 
                        variants={ani_obj}
                        initial="hidden"
                        animate={btn2_controls}
                        ref={ref_btn2} className={'w-full h-fit ' + (edit ? 'grid grid-cols-2 gap-3' : 'hidden')}>
                            <div className='w-full h-12'>
                                <Admin_add_button label={'ยกเลิก'} 
                                handle_click={() => {
                                    let new_result = [...details];

                                    for(let i = 0; i < new_result.length; i++) {
                                        new_result[i].active = true;
                                        new_result[i].edited = false;

                                        if(i === new_result.length - 1) {
                                            ref_detail.current.value = '';
                                            setDetails(new_result);
                                            btn2_controls.start("hidden");

                                            setTimeout(() => {
                                                //animation controls use 500ms to make button disappear
                                                //so, delay state change to 500ms before it changes to hidden
                                                setEdit(false);
                                                btn1_controls.start("visible");
                                            }, 500);
                                        }
                                    }
                                }}/>
                            </div>

                            <div className='w-full h-12'>
                                <Admin_add_button label={'แก้ไข'}
                                handle_click={() => {
                                    const new_detail = ref_detail.current.value;
                                    let new_result = [...details];

                                    for(let i = 0; i < new_result.length; i++) {
                                        if(i === edited_index) {
                                            new_result[i].detail = new_detail;
                                        }

                                        new_result[i].active = true;
                                        new_result[i].edited = false;

                                        if(i === new_result.length - 1) {
                                            ref_detail.current.value = '';
                                            setDetails(new_result);
                                            btn2_controls.start("hidden");

                                            setTimeout(() => {
                                                //animation controls use 500ms to make button disappear
                                                //so, delay state change to 500ms before it changes to hidden
                                                setEdit(false);
                                                btn1_controls.start("visible");
                                            }, 500);
                                        }
                                    }
                                }}/>
                            </div>
                        </motion.div>

                        <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-4'}>
                            <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_cancel}>ยกเลิก</button>
                            <button type='button' className={'notosan-med w-full h-full py-3 rounded-md ' + ((year !== '' && title !== '' && details.length > 0) ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}
                            onClick={() => {
                                ref_year.current.selectedIndex = 0;
                                ref_title.current.value = '';
                                ref_detail.current.value = '';

                                setYear('');
                                setTitle('');
                                setDetails([]);
                                //Note: do not reset opt_years, because it will be <option> in <select>
                                //setOpt_years([]);
                                setEdit(false);
                                setEdited_index(-1);

                                handle_submit(year, title, details);
                            }}>ยืนยัน</button>
                        </div>
                    </div>

                    <div className='w-full h-fit'>
                        <p className='notosan-bold text-xl text-web-black mb-2'>ตัวอย่าง</p>

                        <div className='w-full h-fit'>
                            <div className='w-full h-fit flex items-stretch'>
                                <div className='relative w-10 sm:w-20 min-w-10 sm:min-w-20 flex items-start justify-center'>
                                    <div className='w-4 sm:w-6 h-4 sm:h-6 bg-web-gold rounded-full translate-y-full'></div>
                                    <div className='absolute top-0 inset-x-1/2 -translate-x-1/2 w-0.5 h-5/6 bg-web-gold translate-y-6'></div>
                                </div>

                                <div className='grow h-fit py-3'>
                                    <p className={'text-web-black notosan-semi text-sm'}>{(year) ? "พ.ศ." + year : "ยังไม่ระบุปี พ.ศ. ของเหตุการณ์"}</p>
                                    <p className={'text-web-black notosan-bold text-xl'}>{(title) ? title : "ยังไม่ระบุหัวข้อของเหตุการณ์"}</p>

                                    {
                                        details.map((x, i) => {
                                            return (
                                                <p key={i} className={'notosan-reg indent-8 text-sm ' + (x.edited ? 'text-web-gold ' : 'text-web-black ') + (x.active ? 'opacity-100' : 'opacity-50')}>{x.detail}</p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

const Edible_Timeline = ({order, event, active, edited, handle_edit, handle_delete}) => {
    return (
        <div className={'w-full h-fit flex gap-1 ' + (active ? 'opacity-100' : 'opacity-50')}>
            <div className='w-5/6'>
                <p className={'overflow-hidden line-clamp-1 ' + (edited ? 'notosan-semi text-mahidol-blue underline' : 'notosan-reg text-web-black')}>{order + 1}. {event}</p>
            </div>

            <div className='w-1/6 grid grid-cols-2'>
                <div className='w-full h-fit' onClick={handle_edit}>
                    <p className={'notosan-reg underline text-center ' + ((active && !edited) ? 'cursor-pointer hover:text-yellow-800' : 'opacity-50')}>แก้ไข</p>
                </div>

                <div className='w-full h-fit' onClick={handle_delete}>
                    <p className={'notosan-reg underline text-center ' + ((active && !edited) ? 'cursor-pointer hover:text-yellow-800' : 'opacity-50')}>ลบ</p>
                </div>
            </div>
        </div>
    )
}

const Example_Timeline = ({order, max_order, year, title, details, handle_edit, handle_delete}) => {
    return (
        <div className='relative w-full h-fit flex items-stretch border border-gray-300 rounded-md my-2'>
            <div className='relative w-10 sm:w-20 min-w-10 sm:min-w-20 flex items-start justify-center'>
                <div className='w-4 sm:w-6 h-4 sm:h-6 bg-web-gold rounded-full translate-y-full'></div>

                {
                    (max_order === 1) ? 
                        null
                    :
                        <>
                            { (order === 0) ? <div className='absolute top-0 inset-x-1/2 -translate-x-1/2 w-0.5 h-full bg-web-gold translate-y-6'></div> : null }
                            { (order === max_order - 1) ? <div className='absolute top-0 inset-x-1/2 -translate-x-1/2 w-0.5 h-6 bg-web-gold'></div> : null }
                            { (order !== 0 && order !== max_order - 1) ? <div className='absolute top-0 inset-x-1/2 -translate-x-1/2 w-0.5 h-full bg-web-gold'></div> : null }
                        </>
                }
            </div>

            <div className='grow h-fit py-3'>
                <p className={'text-web-black notosan-semi text-sm'}>พ.ศ. {year}</p>
                <p className={'text-web-black notosan-bold text-xl'}>{title}</p>
                
                {
                    details.map((y, j) => {
                        return (
                            <p key={j} className='notosan-reg text-web-black indent-8 text-sm'>{y}</p>
                        )
                    })
                }
            </div>

            <div className='absolute top-0 left-0 w-full h-full bg-black/70 rounded-sm flex gap-5 justify-center items-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300'>
                <p className='notosan-reg text-white cursor-pointer hover:underline' onClick={handle_edit}>แก้ไข</p>
                <p className='notosan-reg text-white cursor-pointer hover:underline' onClick={handle_delete}>ลบ</p>
            </div>
        </div>
    )
}

const ModalTestimonial_detail_admin = ({isShow, handle_close, handle_submit, handle_update, selectedID}) => {
    const [ cover, setCover ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ founder, setFounder ] = useState([]);
    const [ url, setURL ] = useState([]);
    const [ status, setStatus ] = useState('');
    const [ description, setDescription ] = useState([]);
    const [ timeline, setTimeline ] = useState([]);

    const [ index_edit_founder, setIndex_edit_founder ] = useState(-1);
    const [ index_edit_url, setIndex_edit_url ] = useState(-1);
    const [ index_edit_description, setIndex_edit_description ] = useState(-1);
    const [ index_edit_timeline, setIndex_edit_timeline ] = useState(-1);

    const [ modal_add_img, setModal_add_img ] = useState(false);
    const [ modal_add_title, setModal_add_title ] = useState(false);
    const [ modal_add_founder, setModal_add_founder ] = useState(false);
    const [ modal_add_url, setModal_add_url ] = useState(false);
    const [ modal_add_status, setModal_add_status ] = useState(false);
    const [ modal_add_description, setModal_add_description ] = useState(false);
    const [ modal_add_timeline, setModal_add_timeline ] = useState(false);
    const [ modal_waiting, setModal_waiting ] = useState(false);

    const [ active_submit_btn, setActive_submit_btn ] = useState(false);
    const [ loading_finish_main, setLoading_finish_main ] = useState(false);
    const [ loading_finish_url, setLoading_finish_url ] = useState(false);
    const [ loading_finish_des, setLoading_finish_des ] = useState(false);
    const [ loading_finish_timeline, setLoading_finish_timeline ] = useState(false);

    useEffect(() => {
        setActive_submit_btn(cover !== '' && title !== '' && founder.length > 0 && status !== '' && status !== 'default' && description.length > 0 && timeline.length > 0);
    }, [cover, title, founder, url, status, description, timeline]);

    useEffect(() => {
        setIndex_edit_timeline(-1);

        if(selectedID === -1) {
            setModal_waiting(false);
        }
        else {
            setModal_waiting(true);
            setLoading_finish_main(false);
            setLoading_finish_url(false);
            setLoading_finish_des(false);
            setLoading_finish_timeline(false);

            let abortcontroller = new AbortController();

            const fetch_data = async () => {
                await axios.get(php_list.php_testimonials_read_all, { signal: abortcontroller.signal })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);

                    if (response.data.success) {
                        const index = response.data.results.findIndex(x => x.id === selectedID);
                        setCover(response.data.results[index].image);
                        setTitle(response.data.results[index].title);
                        setFounder(response.data.results[index].founders.split(","));
                        setStatus(response.data.results[index].status);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading_finish_main(true);
                });

                await axios.get(php_list.php_testimonials_read_id_url, {
                    signal: abortcontroller.signal,
                    params: {
                        id: selectedID
                    }
                })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        setURL([...response.data.result]);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error loading data: ' + error);
                })
                .finally(() => {
                    setLoading_finish_url(true);
                });

                await axios.get(php_list.php_testimonials_read_id_des, {
                    signal: abortcontroller.signal,
                    params: {
                        id: selectedID
                    }
                })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        let result = [...response.data.result];
                        result.sort((a, b) => a.paragraph - b.paragraph);
                        
                        let result_description = [];
                        for(let i = 0; i < result.length; i++) {
                            result_description.push(result[i].description);
                        }

                        setDescription(result_description);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error loading data: ' + error);
                })
                .finally(() => {
                    setLoading_finish_des(true);
                });

                await axios.get(php_list.php_testimonials_read_id_timeline, {
                    signal: abortcontroller.signal,
                    params: {
                        id: selectedID
                    }
                })
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        let result = [...response.data.result];
                        result.sort((a, b) => a.year - b.year);
                        
                        let result_timeline = [];
                        for(let i = 0; i < result.length; i++) {
                            let obj = {
                                year: result[i].year,
                                title: result[i].title,
                                detail: result[i].detail.split("{{}}"),
                            }

                            result_timeline.push(obj);
                        }

                        setTimeline(result_timeline);
                    } 
                    else {
                        console.log('Error loading data: ' + response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error loading data: ' + error);
                })
                .finally(() => {
                    setLoading_finish_timeline(true);
                });
            }
            
            fetch_data();
            return () => abortcontroller.abort();
        }
    }, [isShow]);

    useEffect(() => {
        if(modal_waiting) {
            if(loading_finish_main, loading_finish_url, loading_finish_des, loading_finish_timeline) {
                setModal_waiting(false);
            }
        }
    }, [loading_finish_main, loading_finish_url, loading_finish_des, loading_finish_timeline]);

    function green_status(status) {
        return (
            <p className='notosan-med text-green-700'>{status}</p>
        )
    }

    function red_status(status) {
        return (
            <p className='notosan-med text-red-700'>{status}</p>
        )
    }

    function yellow_status(status) {
        return (
            <p className='notosan-med text-yellow-700'>{status}</p>
        )
    }

    return (
        <>
            { /* Modal confirm submit */ }
            <div className={'absolute top-0 left-0 w-full h-full z-40 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
                { /* testimonial modal in classlist is used to remove scrollbar as code imported from css file */ }
                <div className='testimonial modal w-11/12 h-full overflow-y-scroll'>
                    <div className='relative w-full h-fit bg-white drop-shadow-md rounded-md px-8 sm:px-20 py-20 sm:pt-32 pb-28 my-10 sm:my-20'>
                        <div className='absolute top-5 right-5 cursor-pointer' id='btn_close' onClick={handle_close}>
                            <box-icon name='x' color='#555555'></box-icon>
                        </div>

                        <div className='w-full h-fit block lg:flex gap-10'>
                            {
                                (cover === null || cover === undefined || cover === '') ?
                                    <div className='w-full lg:w-60 h-96'>
                                        <Admin_add_button label={"เพิ่มรูปภาพ"} handle_click={() => setModal_add_img(true)}/>
                                    </div>
                                :
                                    <div className='relative w-60 h-96 min-w-60 rounded-md cursor-pointer'>
                                        <img src={cover} className='w-full h-full object-cover rounded-md'/>

                                        <div onClick={() => setModal_add_img(true)}
                                        className='absolute top-0 left-0 w-full h-full bg-black/70 rounded-md flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300'>
                                            <p className='notosan-reg text-white'>เปลี่ยนรูป</p>
                                        </div>
                                    </div>
                            }

                            <div className='grow'>
                                <div className='w-full h-fit mt-6 lg:mt-0'>
                                    <p className='notosan-bold text-xl text-web-black'>โครงการ/กิจกรรม</p>

                                    {
                                        (title === null || title === undefined || title === '') ?
                                            <div className='w-full h-12 mt-2'>
                                                <Admin_add_button label={"เพิ่มชื่อโครงการ/กิจกรรม"} handle_click={() => setModal_add_title(true)}/>
                                            </div>
                                        :
                                            <div className='relative w-full h-fit cursor-pointer' onClick={() => setModal_add_title(true)}>
                                                <p className='notosan-reg text-web-black'>{title}</p>

                                                <div className='absolute top-0 left-0 w-full h-full bg-black/70 rounded-sm flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300'>
                                                    <p className='notosan-reg text-white'>เปลี่ยนชื่อโครงการ/กิจกรรม</p>
                                                </div>
                                            </div>
                                    }
                                </div>

                                <div className='w-full h-fit mt-6'>
                                    <p className='notosan-bold text-xl text-web-black'>ผู้รับผิดชอบโครงการ/กิจกรรม</p>

                                    {
                                        founder.map((x, i) => {
                                            return (
                                                <div key={i} className='relative w-full h-fit'>
                                                    <p className='notosan-reg text-web-black'>{i + 1}. {x}</p>

                                                    <div className='absolute top-0 left-0 w-full h-full bg-black/70 rounded-sm flex gap-5 justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300'>
                                                        <p className='notosan-reg text-white cursor-pointer hover:underline'
                                                        onClick={() => {
                                                            setIndex_edit_founder(i);
                                                            setModal_add_founder(true);
                                                        }}>แก้ไข</p>

                                                        <p className='notosan-reg text-white cursor-pointer hover:underline'
                                                        onClick={() => {
                                                            let new_result = [...founder];
                                                            new_result.splice(i, 1);
                                                            setFounder(new_result);
                                                        }}>ลบ</p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }

                                    <div className='w-full h-12 mt-2'>
                                        <Admin_add_button label={"เพิ่มผู้รับผิดชอบโครงการ/กิจกรรม"} handle_click={() => setModal_add_founder(true)}/>
                                    </div>
                                </div>

                                <div className='w-full h-fit mt-6'>
                                    <p className='notosan-bold text-xl text-web-black'>เว็บไซต์ที่เกี่ยวข้อง</p>

                                    {
                                        url.map((x, i) => {
                                            return (
                                                <div className='relative w-full h-fit'>
                                                    <Link key={i} to={x.url} target="_blank" rel="noopener noreferrer">
                                                        <p className='notosan-reg text-web-black cursor-default'>{i + 1}. <span className='notosan-reg text-web-black cursor-pointer hover:text-web-gold hover:underline'>{x.title}</span></p>
                                                    </Link>

                                                    <div className='absolute top-0 left-0 w-full h-full bg-black/70 rounded-sm flex gap-5 justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300'>
                                                        <p className='notosan-reg text-white cursor-pointer hover:underline'
                                                        onClick={() => {
                                                            setIndex_edit_url(i);
                                                            setModal_add_url(true);
                                                        }}>แก้ไข</p>

                                                        <p className='notosan-reg text-white cursor-pointer hover:underline'
                                                        onClick={() => {
                                                            let new_result = [...url];
                                                            new_result.splice(i, 1);
                                                            setURL(new_result);
                                                        }}>ลบ</p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }

                                    <div className='w-full h-12 mt-2'>
                                        <Admin_add_button label={"เพิ่มลิงก์เว็บไซต์ที่เกี่ยวข้อง"} handle_click={() => setModal_add_url(true)}/>
                                    </div>
                                </div>

                                <div className='w-full h-fit mt-6'>
                                    <p className='notosan-bold text-xl text-web-black'>สถานะการดำเนินโครงการ/กิจกรรม</p>

                                    {
                                        (status === null || status === undefined || status === '' || status === 'default') ?
                                            <div className='w-full h-12 mt-2'>
                                                <Admin_add_button label={"เพิ่มสถานะการดำเนินโครงการ/กิจกรรม"} handle_click={() => setModal_add_status(true)}/>
                                            </div>
                                        :
                                            <div className='relative w-full h-fit'>
                                                { (status === 'กำลังดำเนินการ') ? green_status(status) : null }
                                                { (status === 'เสร็จสิ้นการดำเนินงาน') ? green_status(status) : null }
                                                { (status === 'เสร็จสิ้นการดำเนินงาน และอยู่ระหว่างการขยายผล') ? green_status(status) : null }
                                                { (status === 'ยกเลิกโครงการ') ? red_status(status) : null }
                                                { (status === 'ระงับชั่วคราว') ? yellow_status(status) : null }

                                                <div onClick={() => setModal_add_status(true)}
                                                className='absolute top-0 left-0 w-full h-full cursor-pointer bg-black/70 rounded-sm flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300'>
                                                    <p className='notosan-reg text-white'>เปลี่ยนสถานะการดำเนินโครงการ/กิจกรรม</p>
                                                </div>
                                            </div>
                                    }
                                </div>

                                <div className='w-full h-fit mt-6'>
                                    <p className='notosan-bold text-xl text-web-black'>รายละเอียดของโครงการ/กิจกรรม</p>

                                    {
                                        description.map((x, i) => {
                                            return (
                                                <div key={i} className='relative w-full h-fit'>
                                                    <p className='notosan-reg text-web-black indent-10 sm:indent-20'>{x}</p>

                                                    <div className='absolute top-0 left-0 w-full h-full bg-black/70 rounded-sm flex gap-5 justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300'>
                                                        <p className='notosan-reg text-white cursor-pointer hover:underline'
                                                        onClick={() => {
                                                            setIndex_edit_description(i);
                                                            setModal_add_description(true);
                                                        }}>แก้ไข</p>
                                                        
                                                        <p className='notosan-reg text-white cursor-pointer hover:underline'
                                                        onClick={() => {
                                                            let new_result = [...description];
                                                            new_result.splice(i, 1);
                                                            setDescription(new_result);
                                                        }}
                                                        >ลบ</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className='w-full h-12 mt-2'>
                                        <Admin_add_button label={"เพิ่มรายละเอียดของโครงการ/กิจกรรม"} handle_click={() => setModal_add_description(true)}/>
                                    </div>
                                </div>

                                <div className='w-full h-fit mt-8'>
                                    <p className='notosan-bold text-xl text-web-black mb-2'>รายละเอียดการดำเนินการ</p>

                                    {
                                        timeline.map((x, i) => {
                                            return (
                                                <Example_Timeline key={i} order={i} max_order={timeline.length} year={x.year} title={x.title} details={x.detail}
                                                handle_edit={() => {
                                                    setIndex_edit_timeline(i);
                                                    setModal_add_timeline(true);
                                                }}
                                                handle_delete={() => {
                                                    let new_result = [...timeline];
                                                    new_result.splice(i, 1);
                                                    setTimeline(new_result);
                                                }}/>
                                            )
                                        })
                                    }

                                    <div className='w-full h-12 mt-2'>
                                        <Admin_add_button label={"เพิ่มรายละเอียดการดำเนินการ"} handle_click={() => setModal_add_timeline(true)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-12'}>
                            <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_close}>ปิดหน้านี้</button>
                            <button type='button' className={'notosan-med w-full h-full py-3 rounded-md ' + (active_submit_btn ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}
                            onClick={() => {
                                if(active_submit_btn) {
                                    if(selectedID === -1) {
                                        handle_submit(cover, title, founder, url, status, description, timeline);
                                    }
                                    else {
                                        handle_update(cover, title, founder, url, status, description, timeline);
                                    }
                                }
                            }}>บันทึกข้อมูล</button>
                        </div>
                    </div>
                </div>
            </div>

            { /* Modal backdrop */ }
            <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-30 ' + (isShow ? 'block' : 'hidden')}></div>

            { /* Other Modal */ }
            { /* Add Cover */ }
            <Modal_add_image isShow={modal_add_img} handle_cancel={() => setModal_add_img(false)} 
            handle_submit={(result) => {
                setCover(result);
                setModal_add_img(false);
            }}/>

            { /* Add Title */ }
            <Modal_add_text isShow={modal_add_title} label={"กรุณาระบุหัวข้อของโครงการ/กิจกรรม"} handle_cancel={() => setModal_add_title(false)}
            handle_submit={(result) => {
                setTitle(result);
                setModal_add_title(false);
            }}/>

            { /* Add Founder */ }
            <Modal_add_text isShow={modal_add_founder} label={"กรุณาระบุผู้รับผิดชอบโครงการ/กิจกรรม"} handle_cancel={() => setModal_add_founder(false)}
            handle_submit={(result) => {
                let new_result = [...founder];

                if(index_edit_founder !== -1) {
                    new_result[index_edit_founder] = result;
                }
                else {
                    new_result.push(result);
                }

                setIndex_edit_founder(-1);
                setFounder(new_result);
                setModal_add_founder(false);
            }}/>

            { /* Add Url */ }
            <Modal_add_url isShow={modal_add_url} handle_cancel={() => setModal_add_url(false)}
            handle_submit={(result_title, result_url) => {
                let new_result = [...url];
                let obj = { title: result_title, url: result_url };
                
                if(index_edit_url !== -1) {
                    new_result[index_edit_url] = obj;
                }
                else {
                    new_result.push(obj);
                }

                setIndex_edit_url(-1);
                setURL(new_result);
                setModal_add_url(false);
            }}/>

            <Modal_add_status isShow={modal_add_status} handle_cancel={() => setModal_add_status(false)}
            handle_submit={(result) => {
                setStatus(result);
                setModal_add_status(false);
            }}/>

            <Modal_add_textarea isShow={modal_add_description} label={'กรุณาระบุรายละเอียดของโครงการ/กิจกรรม (สำหรับ 1 ย่อหน้า)'}
            handle_cancel={() => setModal_add_description(false)}
            handle_submit={(result) => {
                let new_result = [...description];

                if(index_edit_description !== -1) {
                    new_result[index_edit_description] = result;
                }
                else {
                    new_result.push(result);
                }

                setIndex_edit_description(-1);
                setDescription(new_result);
                setModal_add_description(false);
            }}/>

            <Modal_add_timeline isShow={modal_add_timeline} 
            handle_cancel={() => setModal_add_timeline(false)}
            handle_submit={(year, title, detail) => {
                let format_timeline = [];

                for(let i = 0; i < detail.length; i++) {
                    format_timeline.push(detail[i].detail);
                }
                
                let obj = {
                    year: parseInt(year),
                    title: title,
                    detail: format_timeline,
                };

                let new_result = [...timeline];

                if(index_edit_timeline !== -1) {
                    new_result[index_edit_timeline] = obj;
                }
                else {
                    new_result.push(obj);
                }

                setTimeline([...new_result].sort((a, b) => a.year - b.year));
                setModal_add_timeline(false);
            }}/>

            <Modal_Waiting isShow={modal_waiting}/>
        </>
    )
}

export default ModalTestimonial_detail_admin