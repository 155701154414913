import React from 'react';
import title_decorate from '../../../assets/decorate/title_decorate.svg';
import RoundFullButton from '../../Components/Button/RoundFullButton';

const Footer_contact_form = ({handle_submit}) => {
  return (
    <>
        <div className='relative w-full h-fit flex flex-col justify-center z-20'>
            <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ติดต่อเรา</h3>
            <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
        </div>

        <form className='relative mx-10 sm:mx-28 z-20' name='contact_form' id='contact_form'>
            <div className='grid grid-cols-2 gap-5'>
                <input className='w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 focus:outline focus:outline-web-gold focus:outline-2 focus:outline-offset-1'
                type='text' name='name' id='contact_name' placeholder='ชื่อ-นามสกุล'/>

                <input className='w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 focus:outline focus:outline-web-gold focus:outline-2 focus:outline-offset-1'
                type='number' name='telephone' id='contact_telephone' placeholder='เบอร์โทรศัพท์'/>
            </div>

            <input className='w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 my-8 focus:outline focus:outline-web-gold focus:outline-2 focus:outline-offset-1'
            type='text' name='email' id='contact_email' placeholder='อีเมลสำหรับติดต่อกลับ'/>

            <textarea className='w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 focus:outline focus:outline-web-gold focus:outline-2 focus:outline-offset-1'
            type='text' name='message' id='contact_message' placeholder='รายละเอียด' rows={10}/>
        </form>

        <div className='relative w-full h-fit flex justify-center my-10 z-20'>
            <RoundFullButton text={'ส่งข้อมูล'} onclick={handle_submit}/>
        </div>
    </>
  )
}

export default Footer_contact_form