import React, { useEffect, useState } from 'react';

const Page = ({num, active, handle_click}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => handle_click(num)}
        className={'w-10 h-10 rounded-full flex justify-center items-center cursor-pointer ' + (active ? 'bg-web-gold' : 'bg-transparent hover:bg-web-gold')}>
            {
                active ? 
                    <p className={'notosan-reg text-white'}>{num}</p>
                :
                    <p className={'notosan-reg ' + (hover ? 'text-white' : 'text-web-black')}>{num}</p>
            }
        </div>
    )
}

const Button = ({text, handle_click}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_click}
        className={'notosan-reg px-6 py-1.5 rounded-full border border-gray-300 cursor-pointer ' + (hover ? 'bg-zinc-700 text-white' : 'bg-transparent')}>{text}</div>
    )
}

const Separater = () => {
    return (
        <div className='notosan-reg py-1.5'>...</div>
    )
}

const Pagination = ({current_page, max_page, handle_change_page}) => {
    const [ pages, setPages ] = useState([]);
    const [ all_pages, setAll_pages ] = useState([]);

    useEffect(() => {
        if(max_page <= 6) {
            let new_pages = [];

            for(let i = 1; i <= max_page; i++) {
                new_pages.push(i);
    
                if(i === max_page) {
                    setPages(new_pages);
                }
            }
        }
        else {
            let new_pages = [];

            if(current_page > 2) {
                new_pages.push(1);
                new_pages.push('...');
            }
            
            if(current_page - 1 > 0) {
                new_pages.push(current_page - 1);
            }

            new_pages.push(current_page);

            if(current_page + 1 < max_page) {
                new_pages.push(current_page + 1);
                new_pages.push('...');
            }

            if(current_page !== max_page) {
                new_pages.push(max_page);
            }

            setPages(new_pages);
        }
    }, [current_page, max_page]);

    useEffect(() => {
        let new_pages = [];

        for(let i = 1; i <= max_page; i++) {
            new_pages.push(i);

            if(i === max_page) {
                setAll_pages(new_pages);
            }
        }
    }, [max_page]);

    return (
        <>
            {
                (max_page > 1) ?
                    <div className='w-fit h-full flex justify-end items-center gap-2 pr-1'>
                        {
                            pages.map((x, i) => {
                                return (
                                    (x === '...') ?
                                        <Separater/>
                                    :
                                        <Page key={i} num={x} active={current_page === x} handle_click={handle_change_page}/>
                                )  
                            })
                        }
                    </div>
                :
                    null
            }

            {
                (max_page > 1) ?
                    <Button text={'ต่อไป'} handle_click={() => handle_change_page(current_page + 1)}/>
                :
                    null
            }
            
            {
                (max_page > 6) ?
                    <select className='notosan-reg px-4 py-1.5 rounded-full border border-gray-300 cursor-pointer' onChange={(e) => handle_change_page(e.target.value)}>
                        <option value={'ไปยังหน้า'}>ไปยังหน้า...</option>

                        {
                            all_pages.map((x, i) => {
                                return (
                                    <option key={i} value={x}>{x}</option>
                                )
                            })
                        }
                    </select>
                :
                    null
            }
        </>
    )
}

export default Pagination