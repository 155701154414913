import React, { useState } from 'react';

const RoundFullButton = ({text, onclick}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <button className={'border border-gray-500 py-2 px-10 rounded-full transition-colors duration-300 ' + (hover ? 'bg-zinc-700 text-white' : 'bg-transparent')}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => onclick()}>
            <span className={'notosan-reg transition-colors duration-300 ' + (hover ? 'text-white' : 'text-web-black')}>{text}</span>
        </button>
    )
}

export default RoundFullButton