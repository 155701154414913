import React, { useState } from 'react';

const HomeAboutCustomNavigation = ({boxicon_name, handle_onclick}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='w-fit h-fit cursor-pointer translate-y-1' 
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_onclick}>
            <box-icon name={boxicon_name} color={hover ? '#c29958' : '#222222'}></box-icon>
        </div>
    )
}

export default HomeAboutCustomNavigation