import React from 'react';
import Header from '../Components/Header/Header';
import HomeIntroduction from './HomeIntroduction';
import HomeAbout from './HomeAbout/HomeAbout';
import HomeAssociation from './HomeAssociation';
import HomeTestimonial from './HomeTestimonial/HomeTestimonial';
import HomeNews from './HomeNews';
import HomeActivity from './HomeActivity';
import Footer from '../Components/Footer/Footer';

const Home = () => {
  return (
    <div className='relative w-screen h-screen z-0'>
      { /* Content of Home */ }
      <div className='w-screen h-screen overflow-y-scroll'>
        <Header/>

        <HomeIntroduction/>
        <HomeAbout/>
        <HomeAssociation/>
        <HomeTestimonial/>
        <HomeNews/>
        <HomeActivity/>

        <Footer form={true}/>
      </div>

      { /* Any modal */ }
    </div>
  )
}

export default Home